/**
 * @owner @Appboy/reporting
 */

import type { I18nT } from "@lib/dashboardI18n";

export type MetricsLabelsTrans = I18nT<"metrics-labels">;

const metricLabelGetter = (t: I18nT<"metrics-labels">) => ({
  "AMP Clicks": t("amp-clicks", "AMP Clicks"),
  "AMP Opens": t("amp-opens", "AMP Opens"),
  "Audience %": t("audience-percent", "Audience %"),
  "BCC Sends": t("bcc-sends", "BCC Sends"),
  "Body Clicks": t("body-clicks", "Body Clicks"),
  "Button 1": t("button-1-clicks", "Button 1"),
  "Button 2": t("button-2-clicks", "Button 2"),
  "Button Clicks": t("button-clicks", "Button Clicks"),
  "Change from Control": t("change-from-control", "Change from Control"),
  "Click to Open Rate": t("click-to-open-rate", "Click to Open Rate"),
  "Clicked and Converted (#)": t("clicked-and-converted-number", "Clicked and Converted (#)"),
  "Clicked and Converted (#) Tooltip": t(
    "clicked-and-converted-number-tooltip",
    "Number of users who converted after clicking the message."
  ),
  "Clicked and Converted (%)": t("clicked-and-converted-percent", "Clicked and Converted (%)"),
  "Clicked and Converted (%) Tooltip": t(
    "clicked-and-converted-percent-tooltip",
    "The percent of users who converted after clicking the message. (Clicked and Converted Number/Unique Recipients)"
  ),
  "Confirmed Deliveries": t("confirmed-deliveries", "Confirmed Deliveries"),
  "Conversion Event Name": t("conversion-event-name", "Conversion Event Name"),
  "Conversion Rate": t("conversion-rate", "Conversion Rate"),
  "Daily Active Users": t("daily-active-users", "Daily Active Users"),
  "Default News Feed Impressions": t("default-news-feed-impressions", "Default News Feed Impressions"),
  "Delivery Failures": t("delivery-failures", "Delivery Failures"),
  "Direct Opens": t("direct-opens", "Direct Opens"),
  "Eligible Population": t("eligible-population", "Eligible Population"),
  "Est. Real Open Rate": t("est-real-open-rate", "Est. Real Open Rate"),
  "Estimated Group Size": t("estimated-group-size", "Estimated Group Size"),
  "Events Per User": t("events-per-user", "Events Per User"),
  "Incremental Uplift Percent": t("incremental-uplift-percent", "Incremental Uplift Percent"),
  "Incremental Uplift": t("incremental-uplift", "Incremental Uplift"),
  "Influenced Opens": t("influenced-opens", "Influenced Opens"),
  "Monthly Active Users": t("monthly-active-users", "Monthly Active Users"),
  "New Users": t("new-users", "New Users"),
  "Opened and Converted (#)": t("opened-and-converted-number", "Opened and Converted (#)"),
  "Opened and Converted (#) Tooltip": t(
    "opened-and-converted-number-tooltip",
    "Number of users who converted after opening the message."
  ),
  "Opened and Converted (%)": t("opened-and-converted-percent", "Opened and Converted (%)"),
  "Opened and Converted (%) Tooltip": t(
    "opened-and-converted-percent-tooltip",
    "The percent of users who converted after opening the message. (Opened and Converted Number/Unique Recipients)"
  ),
  "Opt-Out": t("opt-out", "Opt-Out"),
  "Primary Conversions": t("primary-conversions", "Primary Conversions"),
  "Received and Converted (#)": t("received-and-converted-number", "Received and Converted (#)"),
  "Received and Converted (#) Tooltip": t(
    "received-and-converted-number-tooltip",
    "Number of users who converted after receiving the message."
  ),
  "Received and Converted (%)": t("received-and-converted-percent", "Received and Converted (%)"),
  "Received and Converted (%) Tooltip": t(
    "received-and-converted-percent-tooltip",
    "The percent of users who converted after receiving the message. (Receipt Conversion Number/Unique Recipients)"
  ),
  "Seed Sends": t("seed-sends", "Seed Sends"),
  "Sends to Carrier": t("sends-to-carrier", "Sends to Carrier"),
  "Sessions by Hour": t("sessions-by-hour", "Sessions by Hour"),
  "Sessions per MAU": t("sessions-per-mau", "Sessions per MAU"),
  "Subscription Groups": t("subscription-groups", "Subscription Groups"),
  "Test Sends": t("test-sends", "Test Sends"),
  "Total AMP Clicks": t("total-amp-clicks", "Total AMP Clicks"),
  "Total AMP Opens": t("total-amp-opens", "Total AMP Opens"),
  "Total Clicks": t("total-clicks", "Total Clicks"),
  "Total Confirmed Deliveries": t("total-confirmed-deliveries", "Total Confirmed Deliveries"),
  "Total Delivery Failures": t("total-delivery-failures", "Total Delivery Failures"),
  "Total Dismissals": t("total-dismissals", "Total Dismissals"),
  "Total Failures": t("total-failures", "Total Failures"),
  "Total Impressions": t("total-impressions", "Total Impressions"),
  "Total Number of Events": t("total-number-of-events", "Total Number of Events"),
  "Total Opens": t("total-opens", "Total Opens"),
  "Total Reads": t("total-reads", "Total Reads"),
  "Total Rejections": t("total-rejections", "Total Rejections"),
  "Total Sends To Carrier": t("total-sends-to-carrier", "Total Sends To Carrier"),
  "Total Sends": t("total-sends", "Total Sends"),
  "Total Opt-Ins": t("total-opt-ins", "Total Opt-Ins"),
  "Total Opt-Outs": t("total-opt-outs", "Total Opt-Outs"),
  "Total Helps": t("total-helps", "Total Helps"),
  "Unique Clicks": t("unique-clicks", "Unique Clicks"),
  "Unique Dismissals": t("unique-dismissals", "Unique Dismissals"),
  "Unique Impressions": t("unique-impressions", "Unique Impressions"),
  "Unique Opens": t("unique-opens", "Unique Opens"),
  "Unique Recipients": t("unique-recipients", "Unique Recipients"),
  Bounces: t("bounces", "Bounces"),
  Clicks: t("clicks", "Clicks"),
  Confidence: t("confidence", "Confidence"),
  Deliveries: t("deliveries", "Deliveries"),
  Errors: t("errors", "Errors"),
  Failures: t("failures", "Failures"),
  Help: t("help", "Help"),
  Impressions: t("impressions", "Impressions"),
  Opens: t("opens", "Opens"),
  Reads: t("reads", "Reads"),
  Rejections: t("rejections", "Rejections"),
  Revenue: t("revenue", "Revenue"),
  Sends: t("sends", "Sends"),
  Sessions: t("sessions", "Sessions"),
  Spam: t("spam", "Spam"),
  Stickiness: t("stickiness", "Stickiness"),
  Uninstalls: t("uninstalls", "Uninstalls"),
  Unsubscribes: t("unsubscribes", "Unsubscribes"),
  Variation: t("variation", "Variation"),
});

export type MetricsLabelsOptions = keyof ReturnType<typeof metricLabelGetter>;

export default metricLabelGetter;
