/**
 * @owner @Appboy/messaging-and-automation
 */

import type { I18nT } from "@lib/dashboardI18n";

export type GenderType = "male" | "female" | "other" | "unknown" | "not applicable" | "prefer not to say";

type GenderLabel = { [k in GenderType]: string };

export type GenderLabelsTrans = I18nT<"gender-labels">;

const genderLabelsGetter = (t: I18nT<"gender-labels">): GenderLabel => {
  return {
    male: t("male", "male"),
    female: t("female", "female"),
    other: t("other", "other"),
    unknown: t("unknown", "unknown"),
    "not applicable": t("not-applicable", "not applicable"),
    "prefer not to say": t("prefer-not-to-say", "prefer not to say"),
  };
};

export type GenderLabelsOptions = keyof ReturnType<typeof genderLabelsGetter>;

export default genderLabelsGetter;
