/**
 * @owner @Appboy/messaging-and-automation
 * @fileoverview i18n utilities for labelling time units
 */

import type { I18nT } from "@lib/dashboardI18n";
import type { Weekday } from "@lib/time";

/**
 * this piggybacks on an existing set of translations rather than create our own
 */
export type DayOfWeekLabelsTrans = I18nT<"dates">;

const shortDayOfWeekLabelsGetter = (t: I18nT<"dates">): { [k in Weekday]: string } => ({
  Monday: t("monday-short", "Mon"),
  Tuesday: t("tuesday-short", "Tue"),
  Wednesday: t("wednesday-short", "Wed"),
  Thursday: t("thursday-short", "Thu"),
  Friday: t("friday-short", "Fri"),
  Saturday: t("saturday-short", "Sat"),
  Sunday: t("sunday-short", "Sun"),
});

export type DayOfWeekLabelsOptions = keyof ReturnType<typeof shortDayOfWeekLabelsGetter>;

export default shortDayOfWeekLabelsGetter;
