/**
 * @owner @Appboy/messaging-and-automation
 * @fileoverview i18n utilities for labelling time units
 */

import type { I18nT } from "@lib/dashboardI18n";
import type { Weekday } from "@lib/time";

/**
 * this piggybacks on an existing set of translations rather than create our own
 */
export type DayOfWeekLabelsTrans = I18nT<"dates">;

const dayOfWeekLabelGetter = (t: I18nT<"dates">): { [k in Weekday]: string } => ({
  Monday: t("monday", "Monday"),
  Tuesday: t("tuesday", "Tuesday"),
  Wednesday: t("wednesday", "Wednesday"),
  Thursday: t("thursday", "Thursday"),
  Friday: t("friday", "Friday"),
  Saturday: t("saturday", "Saturday"),
  Sunday: t("sunday", "Sunday"),
});

export type DayOfWeekLabelsOptions = keyof ReturnType<typeof dayOfWeekLabelGetter>;

export default dayOfWeekLabelGetter;
