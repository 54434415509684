/**
 * @owner @Appboy/messaging-and-automation
 * @fileoverview i18n utilities for labelling Canvas Step Labels
 */

import type { I18nT } from "@lib/dashboardI18n";

/**
 * This duplicates the `StepType` exported from `@canvas/Steps/Steps.types`
 * Since these label getters live in `@lib` and the step types are defined in a module
 * namespace that depends on it, we can either hoist them up or simply duplicate them
 * here. The latter is preferred since we can mechanically check that the types for these
 * labels and the types exported from `@canvas` are equivalent.
 */
export type StepType =
  // <DO NOT REMOVE THIS COMMENT FOR CanvasStepGenerator> (1)
  | "context-update"
  | "llm"
  | "feature-flag"
  | "custom-code"
  | "message"
  | "delay"
  | "action-paths"
  | "experiment"
  | "full"
  | "decision-split"
  | "facebook-audience"
  | "google-audience"
  | "tiktok-audience"
  | "audience-sync"
  | "user-update"
  | "audience-paths";

// for some reason, we are defining "control" here, even though it's a variant type
type ExtendedStepType = StepType | "control";

export type canvasStepsLabelsTrans = I18nT<"canvas-steps-labels">;

type StepLabel = { [k in ExtendedStepType]: string };

export const canvasStepsLabelsGetter = (t: I18nT<"canvas-steps-labels">): StepLabel => ({
  "action-paths": t("action-paths", "Action Paths"),
  experiment: t("experiment-step-label", "Experiment Paths"),
  full: t("full-step-label", "Full Step"),
  "decision-split": t("decision-split-step-label", "Decision Split"),
  delay: t("delay-step-label", "Delay"),
  message: t("message-step-label", "Message"),
  "facebook-audience": t("facebook-audience-step-label", "Facebook Audience"),
  "google-audience": t("google-audience-step-label", "Google Audience"),
  "tiktok-audience": t("tiktok-audience-step-label", "TikTok Audience"),
  "audience-sync": t("audience-sync-step-label", "Audience Sync"),
  "audience-paths": t("audience-paths-step-label", "Audience Paths"),
  control: t("control-step-label", "Control"),
  "user-update": t("user-update-step-label", "User Update"),
  // <DO NOT REMOVE THIS COMMENT FOR CanvasStepGenerator> (2)
  "context-update": t("context-update-step-label", "Context"),
  llm: t("ai-step-label", "AI Step"),
  "feature-flag": t("feature-flag-step-label", "Feature Flag"),
  "custom-code": t("custom-code-step-label", "Custom Code"),
});

export type canvasStepsLongLabelsTrans = I18nT<"canvas-steps-long-labels">;

export const canvasStepsLongLabelsGetter = (t: I18nT<"canvas-steps-long-labels">): StepLabel => ({
  "action-paths": t("action-paths", "Action Paths Step"),
  experiment: t("experiment-step-label", "Experiment Paths Step"),
  full: t("full-step-label", "Full Step"),
  "decision-split": t("decision-split-step-label", "Decision Split Step"),
  delay: t("delay-step-label", "Delay Step"),
  message: t("message-step-label", "Message Step"),
  "facebook-audience": t("facebook-audience-step-label", "Facebook Audience Step"),
  "google-audience": t("google-audience-step-label", "Google Audience Step"),
  "tiktok-audience": t("tiktok-audience-step-label", "TikTok Audience Step"),
  "audience-sync": t("audience-sync-step-label", "Audience Sync Step"),
  "audience-paths": t("audience-paths-step-label", "Audience Paths Step"),
  control: t("control-step-label", "Control Step"),
  "user-update": t("user-update-step-label", "User Update Step"),
  // <DO NOT REMOVE THIS COMMENT FOR CanvasStepGenerator> (3)
  "context-update": t("context-update-step-label", "Context"),
  llm: t("ai-step-label", "AI Step"),
  "feature-flag": t("feature-flag-step-label", "Feature Flag Step"),
  "custom-code": t("custom-code-step-label", "Custom Code Step"),
});

export type canvasStepsLabelsOptions = keyof ReturnType<typeof canvasStepsLabelsGetter>;
export type canvasStepsLongLabelsOptions = keyof ReturnType<typeof canvasStepsLongLabelsGetter>;

export default canvasStepsLongLabelsGetter;
