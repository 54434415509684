/**
 * @owner @Appboy/email @Appboy/sms
 */

import type { I18nT } from "@lib/dashboardI18n";

export type WarningLabelsTrans = I18nT<"warning-labels">;

const warningLabelsGetter = (t: I18nT<"warning-labels">) => ({
  "Changes will be discarded": t("unsaved-changes-discarded", "If you exit now, your changes will be discarded."),
  "Unsaved Changes": t("unsaved-changes", "You have unsaved changes!"),
});

export type WarningLabelsOptions = keyof ReturnType<typeof warningLabelsGetter>;

export default warningLabelsGetter;
