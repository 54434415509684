/**
 * @owner @Appboy/user-targeting
 * @fileoverview i18n utilities for object referireferentialntail categories
 */

import type { I18nT } from "@lib/dashboardI18n";

export type ObjectCategoryLabelsTrans = I18nT<"object-category-labels">;

const objectCategoryLabelsGetter = (t: I18nT<"object-category-labels">) => ({
  campaigns: t("campaigns", "Campaigns"),
  canvasSteps: t("canvas-steps", "Canvas Steps"),
  canvases: t("canvases", "Canvases"),
  segments: t("segments", "Segments"),
  segmentExtensions: t("segment-extensions", "Segment Extensions"),
  newsFeedItems: t("news-feed-items", "News Feed Items"),
  teams: t("teams", "Teams"),
});

export type ObjectCategoryLabelsOptions = keyof ReturnType<typeof objectCategoryLabelsGetter>;

export default objectCategoryLabelsGetter;
