/**
 * @owner @Appboy/sms
 */

import type { I18nT } from "@lib/dashboardI18n";
import type { SHARED_CONSTANTS_SUBSCRIPTION_GROUP_STATE_CHANGED_SOURCE_OPTIONS as Options } from "@lib/shared_constants.json";
import sharedConstants from "@lib/shared_constants.json";

type Channels = keyof Options;

const { EMAIL, SMS, WHATS_APP, LINE } = sharedConstants.CAMPAIGN_MESSAGE_TYPES;

const subscriptionGroupActionTriggerChannelGetter = (t: I18nT<"user-behaviors">): Record<Channels, string> => ({
  [EMAIL]: t("email-subscription-group-status", "email subscription group status"),
  [SMS]: t("sms-subscription-group-status", "SMS subscription group status"),
  [WHATS_APP]: t("whatsapp-subscription-group-status", "WhatsApp subscription group status"),
  [LINE]: t("line-subscription-group-status", "LINE subscription group status"),
});

export default subscriptionGroupActionTriggerChannelGetter;
