/**
 * @owner @Appboy/messaging-and-automation
 */

import type { I18nT } from "@lib/dashboardI18n";

export type AudienceLabelsTrans = I18nT<"audience-labels">;

const audienceLabelsGetter = (t: I18nT<"audience-labels">) => ({
  "Audience Settings": t("audience-settings", "Audience Settings"),
  Description: t("description", "Description"),
  Filters: t("filters", "Filters"),
  "Global Control Group": t("global-control-group", "Global Control Group"),
  "Rate Limit": t("rate-limit", "Rate Limit"),
  "Recipients Limit": t("recipients-limit", "Recipients Limit"),
  Segments: t("segments", "Segments"),
  "Delivery Validations": t("delivery-validations", "Delivery validations"),
});

export type AudienceLabelsOptions = keyof ReturnType<typeof audienceLabelsGetter>;

export default audienceLabelsGetter;
