/**
 * @owner @Appboy/messaging-and-automation
 * @fileoverview a mapping of common object statuses to their respective i18n label
 */

import type { I18nT } from "@lib/dashboardI18n";
import type { ObjectStatus } from "@lib/status";

export type objectStatusLabelsTrans = I18nT<"object-status-labels">;

const objectStatusLabelsGetter = (t: I18nT<"object-status-labels">): { [k in ObjectStatus]: string } => ({
  draft: t("status-draft", "Draft"),
  active: t("status-active", "Active"),
  stopped: t("status-stopped", "Stopped"),
  archived: t("status-archived", "Archived"),
  idle: t("status-idle", "Idle"),
  processing: t("status-processing", "Processing"),
  inactive: t("status-inactive", "Inactive"),
  inactiveRecentlySent: t("status-inactive-recently-sent", "Inactive but Recently Sent"),
  inactiveRecentlyEntered: t("status-inactive-recently-entered", "Entry Inactive, but User Recently Entered"),
  notArchived: t("status-not-archived", "Not Archived"),
});

export type objectEventLabelsOptions = keyof ReturnType<typeof objectStatusLabelsGetter>;

export default objectStatusLabelsGetter;
