/**
 * @owner @Appboy/reporting
 */

import type { I18nT } from "@lib/dashboardI18n";

export type DeliveryDetailsLabelTrans = I18nT<"delivery-details-labels">;

const deliveryDetailsLabelsGetter = (t: I18nT<"delivery-details-labels">) => ({
  Type: t("type", "Type"),
  Frequency: t("frequency", "Frequency"),
  "Time Zone": t("time-zone", "Time Zone"),
  "Last Sent": t("last-sent", "Last Sent"),
  "Next Send": t("next-send", "Next Send"),
  "Quiet Hours": t("quiet-hours", "Quiet Hours"),
  "Re-eligibility": t("reeligibility", "Re-eligibility"),
  "Campaign ID": t("campaign-id", "Campaign ID"),
  Duration: t("duration", "Duration"),
  "Frequency Capping": t("frequency-capping", "Frequency Capping"),
  "Message Variation ID": t("message-variation-id", "Message Variation ID"),
  "Trigger Event": t("trigger-event", "Trigger Event"),
  "Exception Event": t("exception-event", "Exception Event"),
  "Delay Info": t("delay-info", "Delay Info"),
  "Exception Info": t("exception-info", "Exception Info"),
  "Advancement Info": t("advancement-info", "Advancement Info"),
  Expiration: t("expiration", "Expiration"),
  "Removal Event": t("removal-event", "Removal Event"),
  "Delivery Settings": t("delivery-settings", "Delivery Settings"),
  "Action-based": t("action-based", "Action-based"),
  "API campaign": t("api-campaign", "API campaign"),
  "API-triggered": t("api-triggered", "API-triggered"),
  Scheduled: t("scheduled", "Scheduled"),
  "Card Creation": t("card-creation", "Card Creation"),
  "At campaign launch": t("at-campaign-launch", "At campaign launch"),
  "At first impression": t("at-first-impression", "At first impression"),
  "At step entry": t("at-step-entry", "At step entry"),
  "Intelligent Timing": t("intelligent-timing", "Intelligent Timing"),
  "Use most popular time among all users": t("most-popular-time", "Use most popular time among all users"),
  "Custom fallback time in user's local time zone": t(
    "fallback-time",
    "Custom fallback time in user's local time zone"
  ),
  "Delivery validations": t("delivery-validation", "Delivery validations"),
});

export type DeliveryDetailsLabelOptions = keyof ReturnType<typeof deliveryDetailsLabelsGetter>;

export default deliveryDetailsLabelsGetter;
