/**
 * @owner @Appboy/reporting
 * @fileoverview a mapping of common object statuses to their respective i18n label
 */

import type { I18nT } from "@lib/dashboardI18n";

export type queryTypeLabelsTrans = I18nT<"query-builder">;

export type QueryType = "multiworkspace" | "singleworkspace";

const queryTypeLabelsGetter = (t: I18nT<"query-builder">): { [k in QueryType]: string } => ({
  multiworkspace: t("multi-workspace", "Multi-workspace"),
  singleworkspace: t("single-workspace", "Single-workspace"),
});

export type queryTypeLabelsOptions = keyof ReturnType<typeof queryTypeLabelsGetter>;

export default queryTypeLabelsGetter;
