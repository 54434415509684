/**
 * @owner @Appboy/channels
 */

import type { PushType } from "@lib/campaignMessageTypeConstants";
import type { I18nT } from "@lib/dashboardI18n";

const pushTypesGetter = (t: I18nT<"channel-labels">): { [k in PushType]: string } => ({
  androidPush: t("android-push-type", "Android"),
  iosPush: t("ios-push-type", "iOS"),
  webPush: t("web-push-type", "Web"),
  kindlePush: t("kindle-push-type", "Kindle"),
});

export default pushTypesGetter;
