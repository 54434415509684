/**
 * @owner @Appboy/sms
 * @fileoverview
 *  This file is autogenerated by /dashboard/script/sms/generate_subscription_group_action_trigger_source_getter.rb.
 *  Any changes should be made to the ERB template defined in that file.
 */

import type { I18nT } from "@lib/dashboardI18n";
import type { SHARED_CONSTANTS_SUBSCRIPTION_GROUP_STATE_CHANGED_SOURCE_OPTIONS as Options } from "@lib/shared_constants.json";

type KeysOfUnion<T> = T extends T ? keyof T : never;
type Sources = KeysOfUnion<Options[keyof Options]>;

const subscriptionGroupActionTriggerSourceGetter = (t: I18nT<"user-behaviors">): Record<Sources, string> => ({
  REST_API: t("rest-api", "REST API"),
  SDK: t("sdk", "SDK"),
  CSV_IMPORT: t("csv-import", "CSV Import"),
  SHOPIFY_PROVIDER: t("shopify", "Shopify"),
  INBOUND_SMS: t("inbound-sms", "Inbound Message"),
  PREFERENCE_CENTER: t("preference-center", "Preference Center"),
  CANVAS_USER_UPDATE_STEP: t("canvas-user-update-step", "Canvas User Update Step"),
  PROVIDER_EVENT: t("provider-event", "Provider Event"),
  LIST_UNSUBSCRIBE: t("list-unsubscribe", "List-Unsubscribe"),
});

export default subscriptionGroupActionTriggerSourceGetter;
