/**
 * @owner @Appboy/messaging-and-automation
 * @fileoverview i18n utilities for labelling discrete actions that can be effected on
 *   business objects in Dashboard
 */

import type { I18nT } from "@lib/dashboardI18n";
import type { ObjectAction } from "@lib/status";

export type ObjectActionLabelsTrans = I18nT<"object-action-labels">;

const objectActionLabelsGetter = (t: I18nT<"object-action-labels">): { [k in ObjectAction]: string } => ({
  edit: t("edit", "Edit"),
  archive: t("archive", "Archive"),
  unarchive: t("unarchive", "Unarchive"),
  stop: t("stop", "Stop"),
  resume: t("resume", "Resume"),
  restore: t("restore", "Restore Interaction Data"),
  duplicate: t("duplicate", "Duplicate"),
  copyToWorkspace: t("copy-to-workspace", "Copy to workspace"),
  createTemplate: t("create-template", "Save as template"),
});

export type ObjectActionLabelsOptions = keyof ReturnType<typeof objectActionLabelsGetter>;

export default objectActionLabelsGetter;
