/**
 * @owner @Appboy/email @Appboy/sms
 */

import type { I18nT } from "@lib/dashboardI18n";

export type PlaceholderLabelsTrans = I18nT<"placeholder-labels">;

const placeholderLabelsGetter = (t: I18nT<"placeholder-labels">) => ({
  "App Version": t("select-app-versions", "Select App Versions..."),
  Platform: t("select-platforms", "Select Platforms..."),
  Segment: t("select-segments", "Select segments..."),
  Keywords: t("keywords", "Enter a keyword"),
  "Reply Message": t("reply-message", "Enter Reply Message"),
  "Fuzzy Auto Response": t("fuzzy-auto-response", "Fuzzy auto response text here..."),
  "Keyword Category": t("keyword-category", 'e.g. "Promotion"'),
  "Subscription Group Name": t("subscription-group-name", "Enter Subscription Group Name"),
  "Subscription Group Description": t("subscription-group-description", "Enter Subscription Group Description"),
  "All users": t("all-users", "All users"),
  "All Statuses": t("all-statuses", "All Statuses"),
  "All Tags": t("all-tags", "All Tags"),
  "Add tags": t("add-tags", "Add tags"),
  "All teams": t("all-teams", "All teams"),
  "All Types": t("all-types", "All Types"),
  "All query types": t("all-query-types", "All query types"),
});

export type PlaceholderLabelsOptions = keyof ReturnType<typeof placeholderLabelsGetter>;

export default placeholderLabelsGetter;
