/**
 * @owner @Appboy/frontend-ix
 */

import { featureOn } from "@lib/featureFlipper";

// This just re-exports @lib/featureFlipper but wrapped in an object. This is
// unfortunately necessary for a lot of test code that mocks out feature
// flippers; inside cypress and storybook you should use the faking utilities for useFeatureOn()
export default {
  featureOn,
};
