/**
 * @owner @Appboy/reporting
 */

import type { I18nT } from "@lib/dashboardI18n";

export type BreakdownLabelsTrans = I18nT<"breakdown-labels">;

const breakdownLabelsGetter = (t: I18nT<"breakdown-labels">) => ({
  "App Version": t("app-version", "App Version"),
  "By App Version": t("by-app-version", "By App Version"),
  "By Platform": t("by-platform", "By Platform"),
  "By Segment": t("by-segment", "By Segment"),
  "By Product": t("by-product", "By Product"),
  Platform: t("platform", "Platform"),
  Segment: t("segment", "Segment"),
});

export type BreakdownLabelOptions = keyof ReturnType<typeof breakdownLabelsGetter>;

export default breakdownLabelsGetter;
