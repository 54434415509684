/**
 * @owner @Appboy/messaging-and-automation
 * @fileoverview A mapping of the available types in the UserBehavior type union to their
 *   label in the trigger selection UI
 */

import type { I18nT } from "@lib/dashboardI18n";
import type { UserBehavior } from "@lib/userBehaviorConstants";

export type triggerEventLabelsTrans = I18nT<"trigger-event-labels">;

const triggerEventLabelsGetter = (t: I18nT<"trigger-event-labels">): { [k in UserBehavior]: string } => ({
  SESSIONS: t("SESSIONS", "Start Session"),
  UPGRADED_APP_VERSION: t("UPGRADED-APP-VERSION", "Upgrade App"),
  PURCHASES: t("PURCHASES", "Make Purchase"),
  ANY_PURCHASE: t("ANY-PURCHASE", "Make Any Purchase"),
  PURCHASES_WITH_NESTED_OBJECT: t("PURCHASES-WITH-NESTED-OBJECT", "Make Purchase"),
  CUSTOM_EVENTS: t("CUSTOM-EVENTS", "Perform Custom Event"),
  CUSTOM_EVENTS_WITH_NESTED_OBJECT: t("CUSTOM-EVENTS-WITH-NESTED-OBJECT", "Perform Custom Event"),
  PERFORMED_CONVERSION: t("PERFORMED-CONVERSION", "Perform Conversion Event"),
  CAMPAIGN_INTERACTIONS: t("CAMPAIGN-INTERACTIONS", "Interact With Campaign"),
  WORKFLOW_STEP_INTERACTIONS: t("WORKFLOW-STEP-INTERACTIONS", "Interact With Step"),
  CARD_INTERACTIONS: t("CARD-INTERACTIONS", "Interact With Card"),
  LOCATION_EVENTS: t("LOCATION-EVENTS", "Enter a Location"),
  PERFORMED_CAMPAIGN_EXCEPTION: t("PERFORMED-CAMPAIGN-EXCEPTION", "Perform Exception Event For Campaign"),
  OPENED_EMAIL: t("OPENED-EMAIL", "Open Email"),
  CLICKED_EMAIL: t("CLICKED-EMAIL", "Click Email"),
  ADDED_EMAIL_ADDRESS: t("ADDED-EMAIL-ADDRESS", "Add an Email Address"),
  ATTRIBUTE_UPDATED: t("ATTRIBUTE-UPDATED", "Change Custom Attribute Value"),
  SUBSCRIPTION_STATE_CHANGED: t("SUBSCRIPTION-STATE-CHANGED", "Update Subscription Status"),
  SUBSCRIPTION_GROUP_STATE_CHANGED: t("SUBSCRIPTION-GROUP-STATE-CHANGED", "Update Subscription Group Status"),
  LOCATION_SET_EVENTS: t("LOCATION-SET-EVENTS", "Trigger a Geofence"),
  SENT_SMS_INBOUND_MESSAGE: t("SENT-SMS-INBOUND-MESSAGE", "Send an SMS inbound message"),
  SENT_WHATS_APP_INBOUND_MESSAGE: t("SENT-WHATS-APP-INBOUND-MESSAGE", "Send a WhatsApp inbound message"),
  SENT_LINE_INBOUND_MESSAGE: t("SENT-LINE-INBOUND-MESSAGE", "Send a LINE inbound message"),
  INTERNAL_WORKFLOW_STEP_INTERACTIONS: t("INTERNAL-WORKFLOW-STEP-INTERACTIONS", "Interact With Step"),
  PUSH_ONLY_CAMPAIGN_INTERACTIONS: t("PUSH-ONLY-CAMPAIGN-INTERACTIONS", "Interact With Campaign"),
  CANVAS_CLICK: t("CANVAS-CLICK", "Click Message Sent After Experiment Step"),
  CANVAS_OPEN: t("CANVAS-OPEN", "Open Message Sent After Experiment Step"),
  CATALOG_BACK_IN_STOCK: t("CATALOG-BACK-IN-STOCK", "Perform Back in Stock Event"),
  CATALOG_PRICE_DROP: t("CATALOG-PRICE-DROP", "Perform Price Drop Event"),
  CART_UPDATED: t("CART-UPDATED", "Perform Cart Updated Event"),
  CHECKOUT_COMPLETED: t("CHECKOUT-COMPLETED", "Perform Checkout Completed Event"),
  CHECKOUT_STARTED: t("CHECKOUT-STARTED", "Perform Checkout Started Event"),
});

export type triggerEventLabelsOptions = keyof ReturnType<typeof triggerEventLabelsGetter>;

export default triggerEventLabelsGetter;
