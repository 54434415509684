/**
 * @owner @Appboy/core-objects
 */

import type { I18nT } from "@lib/dashboardI18n";

export type AttributeTypeLabelsTrans = I18nT<"attribute-type-labels">;

const attributeTypeLabelsGetter = (t: I18nT<"attribute-type-labels">) => ({
  Array: t("array", "Array"),
  Error: t("error", "Error"),
  Integer: t("integer", "Number"),
  Number: t("integer", "Number"),
  Mixed: t("mixed", "Mixed"),
  String: t("string", "String"),
  Time: t("time", "Time"),
  TrueClass: t("boolean", "Boolean"),
});

export type AttributeTypeLabelsOptions = keyof ReturnType<typeof attributeTypeLabelsGetter>;

export default attributeTypeLabelsGetter;
