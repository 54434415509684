/**
 * @owner @Appboy/email @Appboy/sms
 */

import type { I18nT } from "@lib/dashboardI18n";
export type LanguageLabelsTrans = I18nT<"language-labels">;

/**
 * This is a generated file:
 * To add to languageLabels, go to dashboard/script/languages/generate-languages.js
 * To add languages, go to shared_code/lib/shared/language-names.json
 *
 * Run script/languages/install-languages.sh after edits to lock in your change
 */
const languageLabelsGetter = (t: I18nT<"language-labels">) => {
  return {
    "Add New Keywords for Afrikaans": t("afrikaans-add-keyword", "Add New Keywords for Afrikaans"),
    "Add New Keywords for Aghem": t("aghem-add-keyword", "Add New Keywords for Aghem"),
    "Add New Keywords for Akan": t("akan-add-keyword", "Add New Keywords for Akan"),
    "Add New Keywords for Albanian": t("albanian-add-keyword", "Add New Keywords for Albanian"),
    "Add New Keywords for Amharic": t("amharic-add-keyword", "Add New Keywords for Amharic"),
    "Add New Keywords for Arabic": t("arabic-add-keyword", "Add New Keywords for Arabic"),
    "Add New Keywords for Armenian": t("armenian-add-keyword", "Add New Keywords for Armenian"),
    "Add New Keywords for Assamese": t("assamese-add-keyword", "Add New Keywords for Assamese"),
    "Add New Keywords for Asu": t("asu-add-keyword", "Add New Keywords for Asu"),
    "Add New Keywords for Aymara": t("aymara-add-keyword", "Add New Keywords for Aymara"),
    "Add New Keywords for Azerbaijani": t("azerbaijani-add-keyword", "Add New Keywords for Azerbaijani"),
    "Add New Keywords for Bafia": t("bafia-add-keyword", "Add New Keywords for Bafia"),
    "Add New Keywords for Bambara": t("bambara-add-keyword", "Add New Keywords for Bambara"),
    "Add New Keywords for Basa": t("basa-add-keyword", "Add New Keywords for Basa"),
    "Add New Keywords for Basque": t("basque-add-keyword", "Add New Keywords for Basque"),
    "Add New Keywords for Belarusian": t("belarusian-add-keyword", "Add New Keywords for Belarusian"),
    "Add New Keywords for Bemba": t("bemba-add-keyword", "Add New Keywords for Bemba"),
    "Add New Keywords for Bena": t("bena-add-keyword", "Add New Keywords for Bena"),
    "Add New Keywords for Bengali": t("bengali-add-keyword", "Add New Keywords for Bengali"),
    "Add New Keywords for Bodo": t("bodo-add-keyword", "Add New Keywords for Bodo"),
    "Add New Keywords for Bosnian": t("bosnian-add-keyword", "Add New Keywords for Bosnian"),
    "Add New Keywords for Breton": t("breton-add-keyword", "Add New Keywords for Breton"),
    "Add New Keywords for Bulgarian": t("bulgarian-add-keyword", "Add New Keywords for Bulgarian"),
    "Add New Keywords for Burmese": t("burmese-add-keyword", "Add New Keywords for Burmese"),
    "Add New Keywords for Cambodian": t("cambodian-add-keyword", "Add New Keywords for Cambodian"),
    "Add New Keywords for Catalan": t("catalan-add-keyword", "Add New Keywords for Catalan"),
    "Add New Keywords for Central Atlas Tamazight": t(
      "central-atlas-tamazight-add-keyword",
      "Add New Keywords for Central Atlas Tamazight"
    ),
    "Add New Keywords for Cherokee": t("cherokee-add-keyword", "Add New Keywords for Cherokee"),
    "Add New Keywords for Chiga": t("chiga-add-keyword", "Add New Keywords for Chiga"),
    "Add New Keywords for Chinese": t("chinese-add-keyword", "Add New Keywords for Chinese"),
    "Add New Keywords for Congo Swahili": t("congo-swahili-add-keyword", "Add New Keywords for Congo Swahili"),
    "Add New Keywords for Cornish": t("cornish-add-keyword", "Add New Keywords for Cornish"),
    "Add New Keywords for Criol": t("criol-add-keyword", "Add New Keywords for Criol"),
    "Add New Keywords for Croatian": t("croatian-add-keyword", "Add New Keywords for Croatian"),
    "Add New Keywords for Czech": t("czech-add-keyword", "Add New Keywords for Czech"),
    "Add New Keywords for Danish": t("danish-add-keyword", "Add New Keywords for Danish"),
    "Add New Keywords for Dawida": t("dawida-add-keyword", "Add New Keywords for Dawida"),
    "Add New Keywords for Douala": t("douala-add-keyword", "Add New Keywords for Douala"),
    "Add New Keywords for Dutch": t("dutch-add-keyword", "Add New Keywords for Dutch"),
    "Add New Keywords for Dzongkha": t("dzongkha-add-keyword", "Add New Keywords for Dzongkha"),
    "Add New Keywords for Ekugusii": t("ekugusii-add-keyword", "Add New Keywords for Ekugusii"),
    "Add New Keywords for English": t("english-add-keyword", "Add New Keywords for English"),
    "Add New Keywords for Esperanto": t("esperanto-add-keyword", "Add New Keywords for Esperanto"),
    "Add New Keywords for Estonian": t("estonian-add-keyword", "Add New Keywords for Estonian"),
    "Add New Keywords for Ewe": t("ewe-add-keyword", "Add New Keywords for Ewe"),
    "Add New Keywords for Ewondo": t("ewondo-add-keyword", "Add New Keywords for Ewondo"),
    "Add New Keywords for Faroese": t("faroese-add-keyword", "Add New Keywords for Faroese"),
    "Add New Keywords for Farsi": t("farsi-add-keyword", "Add New Keywords for Farsi"),
    "Add New Keywords for Filipino": t("filipino-add-keyword", "Add New Keywords for Filipino"),
    "Add New Keywords for Finnish": t("finnish-add-keyword", "Add New Keywords for Finnish"),
    "Add New Keywords for French": t("french-add-keyword", "Add New Keywords for French"),
    "Add New Keywords for Galician": t("galician-add-keyword", "Add New Keywords for Galician"),
    "Add New Keywords for Ganda": t("ganda-add-keyword", "Add New Keywords for Ganda"),
    "Add New Keywords for Georgian": t("georgian-add-keyword", "Add New Keywords for Georgian"),
    "Add New Keywords for German": t("german-add-keyword", "Add New Keywords for German"),
    "Add New Keywords for German Swiss": t("german-swiss-add-keyword", "Add New Keywords for German Swiss"),
    "Add New Keywords for Greek": t("greek-add-keyword", "Add New Keywords for Greek"),
    "Add New Keywords for Greenlandic": t("greenlandic-add-keyword", "Add New Keywords for Greenlandic"),
    "Add New Keywords for Guarani": t("guarani-add-keyword", "Add New Keywords for Guarani"),
    "Add New Keywords for Gujarati": t("gujarati-add-keyword", "Add New Keywords for Gujarati"),
    "Add New Keywords for Hausa": t("hausa-add-keyword", "Add New Keywords for Hausa"),
    "Add New Keywords for Hawaiian": t("hawaiian-add-keyword", "Add New Keywords for Hawaiian"),
    "Add New Keywords for Hebrew": t("hebrew-add-keyword", "Add New Keywords for Hebrew"),
    "Add New Keywords for Hindi": t("hindi-add-keyword", "Add New Keywords for Hindi"),
    "Add New Keywords for Hungarian": t("hungarian-add-keyword", "Add New Keywords for Hungarian"),
    "Add New Keywords for Icelandic": t("icelandic-add-keyword", "Add New Keywords for Icelandic"),
    "Add New Keywords for Igbo": t("igbo-add-keyword", "Add New Keywords for Igbo"),
    "Add New Keywords for Indonesian": t("indonesian-add-keyword", "Add New Keywords for Indonesian"),
    "Add New Keywords for Inuktitut": t("inuktitut-add-keyword", "Add New Keywords for Inuktitut"),
    "Add New Keywords for Irish": t("irish-add-keyword", "Add New Keywords for Irish"),
    "Add New Keywords for Italian": t("italian-add-keyword", "Add New Keywords for Italian"),
    "Add New Keywords for Japanese": t("japanese-add-keyword", "Add New Keywords for Japanese"),
    "Add New Keywords for Javanese": t("javanese-add-keyword", "Add New Keywords for Javanese"),
    "Add New Keywords for Jola-Fonyi": t("jola-fonyi-add-keyword", "Add New Keywords for Jola-Fonyi"),
    "Add New Keywords for Kabyle": t("kabyle-add-keyword", "Add New Keywords for Kabyle"),
    "Add New Keywords for Kalenjin": t("kalenjin-add-keyword", "Add New Keywords for Kalenjin"),
    "Add New Keywords for Kamba": t("kamba-add-keyword", "Add New Keywords for Kamba"),
    "Add New Keywords for Kannada": t("kannada-add-keyword", "Add New Keywords for Kannada"),
    "Add New Keywords for Kashmiri": t("kashmiri-add-keyword", "Add New Keywords for Kashmiri"),
    "Add New Keywords for Kazakh": t("kazakh-add-keyword", "Add New Keywords for Kazakh"),
    "Add New Keywords for Kiembu": t("kiembu-add-keyword", "Add New Keywords for Kiembu"),
    "Add New Keywords for Kikuyu": t("kikuyu-add-keyword", "Add New Keywords for Kikuyu"),
    "Add New Keywords for Kinyarwanda": t("kinyarwanda-add-keyword", "Add New Keywords for Kinyarwanda"),
    "Add New Keywords for Kirghiz": t("kirghiz-add-keyword", "Add New Keywords for Kirghiz"),
    "Add New Keywords for Konkani": t("konkani-add-keyword", "Add New Keywords for Konkani"),
    "Add New Keywords for Korean": t("korean-add-keyword", "Add New Keywords for Korean"),
    "Add New Keywords for Koyraboro Senni": t(
      "koyraboro-senni-add-keyword",
      "Add New Keywords for Koyraboro Senni"
    ),
    "Add New Keywords for Kurdish": t("kurdish-add-keyword", "Add New Keywords for Kurdish"),
    "Add New Keywords for Lao": t("lao-add-keyword", "Add New Keywords for Lao"),
    "Add New Keywords for Latin": t("latin-add-keyword", "Add New Keywords for Latin"),
    "Add New Keywords for Latvian": t("latvian-add-keyword", "Add New Keywords for Latvian"),
    "Add New Keywords for Lingala": t("lingala-add-keyword", "Add New Keywords for Lingala"),
    "Add New Keywords for Lithuanian": t("lithuanian-add-keyword", "Add New Keywords for Lithuanian"),
    "Add New Keywords for Luba-Katanga": t("luba-katanga-add-keyword", "Add New Keywords for Luba-Katanga"),
    "Add New Keywords for Luo": t("luo-add-keyword", "Add New Keywords for Luo"),
    "Add New Keywords for Luxembourgish": t("luxembourgish-add-keyword", "Add New Keywords for Luxembourgish"),
    "Add New Keywords for Luyia": t("luyia-add-keyword", "Add New Keywords for Luyia"),
    "Add New Keywords for Macedonian": t("macedonian-add-keyword", "Add New Keywords for Macedonian"),
    "Add New Keywords for Machame": t("machame-add-keyword", "Add New Keywords for Machame"),
    "Add New Keywords for Makhuwa-Meetto": t("makhuwa-meetto-add-keyword", "Add New Keywords for Makhuwa-Meetto"),
    "Add New Keywords for Makonde": t("makonde-add-keyword", "Add New Keywords for Makonde"),
    "Add New Keywords for Malagasy": t("malagasy-add-keyword", "Add New Keywords for Malagasy"),
    "Add New Keywords for Malay": t("malay-add-keyword", "Add New Keywords for Malay"),
    "Add New Keywords for Malayalam": t("malayalam-add-keyword", "Add New Keywords for Malayalam"),
    "Add New Keywords for Maltese": t("maltese-add-keyword", "Add New Keywords for Maltese"),
    "Add New Keywords for Manx": t("manx-add-keyword", "Add New Keywords for Manx"),
    "Add New Keywords for Marathi": t("marathi-add-keyword", "Add New Keywords for Marathi"),
    "Add New Keywords for Masai": t("masai-add-keyword", "Add New Keywords for Masai"),
    "Add New Keywords for Meru": t("meru-add-keyword", "Add New Keywords for Meru"),
    "Add New Keywords for Moldavian": t("moldavian-add-keyword", "Add New Keywords for Moldavian"),
    "Add New Keywords for Mongolian": t("mongolian-add-keyword", "Add New Keywords for Mongolian"),
    "Add New Keywords for Morisyen": t("morisyen-add-keyword", "Add New Keywords for Morisyen"),
    "Add New Keywords for Mundang": t("mundang-add-keyword", "Add New Keywords for Mundang"),
    "Add New Keywords for Nama": t("nama-add-keyword", "Add New Keywords for Nama"),
    "Add New Keywords for Nepali": t("nepali-add-keyword", "Add New Keywords for Nepali"),
    "Add New Keywords for North Ndebele": t("north-ndebele-add-keyword", "Add New Keywords for North Ndebele"),
    "Add New Keywords for Norwegian": t("norwegian-add-keyword", "Add New Keywords for Norwegian"),
    "Add New Keywords for Nuer": t("nuer-add-keyword", "Add New Keywords for Nuer"),
    "Add New Keywords for Nyankole": t("nyankole-add-keyword", "Add New Keywords for Nyankole"),
    "Add New Keywords for Nynorsk": t("nynorsk-add-keyword", "Add New Keywords for Nynorsk"),
    "Add New Keywords for Oriya": t("oriya-add-keyword", "Add New Keywords for Oriya"),
    "Add New Keywords for Oromo": t("oromo-add-keyword", "Add New Keywords for Oromo"),
    "Add New Keywords for Pashto": t("pashto-add-keyword", "Add New Keywords for Pashto"),
    "Add New Keywords for Peul": t("peul-add-keyword", "Add New Keywords for Peul"),
    "Add New Keywords for Polish": t("polish-add-keyword", "Add New Keywords for Polish"),
    "Add New Keywords for Portuguese": t("portuguese-add-keyword", "Add New Keywords for Portuguese"),
    "Add New Keywords for Punjabi": t("punjabi-add-keyword", "Add New Keywords for Punjabi"),
    "Add New Keywords for Quechua": t("quechua-add-keyword", "Add New Keywords for Quechua"),
    "Add New Keywords for Raeto Romance": t("raeto-romance-add-keyword", "Add New Keywords for Raeto Romance"),
    "Add New Keywords for Romanian": t("romanian-add-keyword", "Add New Keywords for Romanian"),
    "Add New Keywords for Rombo": t("rombo-add-keyword", "Add New Keywords for Rombo"),
    "Add New Keywords for Rundi": t("rundi-add-keyword", "Add New Keywords for Rundi"),
    "Add New Keywords for Russian": t("russian-add-keyword", "Add New Keywords for Russian"),
    "Add New Keywords for Rwa": t("rwa-add-keyword", "Add New Keywords for Rwa"),
    "Add New Keywords for Samburu": t("samburu-add-keyword", "Add New Keywords for Samburu"),
    "Add New Keywords for Sami": t("sami-add-keyword", "Add New Keywords for Sami"),
    "Add New Keywords for Sangu": t("sangu-add-keyword", "Add New Keywords for Sangu"),
    "Add New Keywords for Sanskrit": t("sanskrit-add-keyword", "Add New Keywords for Sanskrit"),
    "Add New Keywords for Scottish": t("scottish-add-keyword", "Add New Keywords for Scottish"),
    "Add New Keywords for Sena": t("sena-add-keyword", "Add New Keywords for Sena"),
    "Add New Keywords for Serbian": t("serbian-add-keyword", "Add New Keywords for Serbian"),
    "Add New Keywords for Shambala": t("shambala-add-keyword", "Add New Keywords for Shambala"),
    "Add New Keywords for Shona": t("shona-add-keyword", "Add New Keywords for Shona"),
    "Add New Keywords for Sichuan Yi": t("sichuan-yi-add-keyword", "Add New Keywords for Sichuan Yi"),
    "Add New Keywords for Sindhi": t("sindhi-add-keyword", "Add New Keywords for Sindhi"),
    "Add New Keywords for Sinhalese": t("sinhalese-add-keyword", "Add New Keywords for Sinhalese"),
    "Add New Keywords for Slovak": t("slovak-add-keyword", "Add New Keywords for Slovak"),
    "Add New Keywords for Slovenian": t("slovenian-add-keyword", "Add New Keywords for Slovenian"),
    "Add New Keywords for Soga": t("soga-add-keyword", "Add New Keywords for Soga"),
    "Add New Keywords for Somali": t("somali-add-keyword", "Add New Keywords for Somali"),
    "Add New Keywords for Songhay": t("songhay-add-keyword", "Add New Keywords for Songhay"),
    "Add New Keywords for South Ndebele": t("south-ndebele-add-keyword", "Add New Keywords for South Ndebele"),
    "Add New Keywords for Southern Sotho": t("southern-sotho-add-keyword", "Add New Keywords for Southern Sotho"),
    "Add New Keywords for Spanish": t("spanish-add-keyword", "Add New Keywords for Spanish"),
    "Add New Keywords for Sundanese": t("sundanese-add-keyword", "Add New Keywords for Sundanese"),
    "Add New Keywords for Swahili": t("swahili-add-keyword", "Add New Keywords for Swahili"),
    "Add New Keywords for Swati": t("swati-add-keyword", "Add New Keywords for Swati"),
    "Add New Keywords for Swedish": t("swedish-add-keyword", "Add New Keywords for Swedish"),
    "Add New Keywords for Tachelhit": t("tachelhit-add-keyword", "Add New Keywords for Tachelhit"),
    "Add New Keywords for Tagalog": t("tagalog-add-keyword", "Add New Keywords for Tagalog"),
    "Add New Keywords for Tajiki": t("tajiki-add-keyword", "Add New Keywords for Tajiki"),
    "Add New Keywords for Tamil": t("tamil-add-keyword", "Add New Keywords for Tamil"),
    "Add New Keywords for Tasawaq": t("tasawaq-add-keyword", "Add New Keywords for Tasawaq"),
    "Add New Keywords for Tatar": t("tatar-add-keyword", "Add New Keywords for Tatar"),
    "Add New Keywords for Telugu": t("telugu-add-keyword", "Add New Keywords for Telugu"),
    "Add New Keywords for Teso": t("teso-add-keyword", "Add New Keywords for Teso"),
    "Add New Keywords for Thai": t("thai-add-keyword", "Add New Keywords for Thai"),
    "Add New Keywords for Tibetan": t("tibetan-add-keyword", "Add New Keywords for Tibetan"),
    "Add New Keywords for Tigrinya": t("tigrinya-add-keyword", "Add New Keywords for Tigrinya"),
    "Add New Keywords for Tongan": t("tongan-add-keyword", "Add New Keywords for Tongan"),
    "Add New Keywords for Tsonga": t("tsonga-add-keyword", "Add New Keywords for Tsonga"),
    "Add New Keywords for Tswana": t("tswana-add-keyword", "Add New Keywords for Tswana"),
    "Add New Keywords for Turkish": t("turkish-add-keyword", "Add New Keywords for Turkish"),
    "Add New Keywords for Turkmen": t("turkmen-add-keyword", "Add New Keywords for Turkmen"),
    "Add New Keywords for Uighur": t("uighur-add-keyword", "Add New Keywords for Uighur"),
    "Add New Keywords for Ukrainian": t("ukrainian-add-keyword", "Add New Keywords for Ukrainian"),
    "Add New Keywords for Urdu": t("urdu-add-keyword", "Add New Keywords for Urdu"),
    "Add New Keywords for Uzbek": t("uzbek-add-keyword", "Add New Keywords for Uzbek"),
    "Add New Keywords for Vai": t("vai-add-keyword", "Add New Keywords for Vai"),
    "Add New Keywords for Venda": t("venda-add-keyword", "Add New Keywords for Venda"),
    "Add New Keywords for Vietnamese": t("vietnamese-add-keyword", "Add New Keywords for Vietnamese"),
    "Add New Keywords for Vunjo": t("vunjo-add-keyword", "Add New Keywords for Vunjo"),
    "Add New Keywords for Welsh": t("welsh-add-keyword", "Add New Keywords for Welsh"),
    "Add New Keywords for Xhosa": t("xhosa-add-keyword", "Add New Keywords for Xhosa"),
    "Add New Keywords for Yangben": t("yangben-add-keyword", "Add New Keywords for Yangben"),
    "Add New Keywords for Yiddish": t("yiddish-add-keyword", "Add New Keywords for Yiddish"),
    "Add New Keywords for Yoruba": t("yoruba-add-keyword", "Add New Keywords for Yoruba"),
    "Add New Keywords for Zarma": t("zarma-add-keyword", "Add New Keywords for Zarma"),
    "Add New Keywords for Zulu": t("zulu-add-keyword", "Add New Keywords for Zulu"),
    "Delete Afrikaans": t("afrikaans-delete", "Delete Afrikaans"),
    "Delete Aghem": t("aghem-delete", "Delete Aghem"),
    "Delete Akan": t("akan-delete", "Delete Akan"),
    "Delete Albanian": t("albanian-delete", "Delete Albanian"),
    "Delete Amharic": t("amharic-delete", "Delete Amharic"),
    "Delete Arabic": t("arabic-delete", "Delete Arabic"),
    "Delete Armenian": t("armenian-delete", "Delete Armenian"),
    "Delete Assamese": t("assamese-delete", "Delete Assamese"),
    "Delete Asu": t("asu-delete", "Delete Asu"),
    "Delete Aymara": t("aymara-delete", "Delete Aymara"),
    "Delete Azerbaijani": t("azerbaijani-delete", "Delete Azerbaijani"),
    "Delete Bafia": t("bafia-delete", "Delete Bafia"),
    "Delete Bambara": t("bambara-delete", "Delete Bambara"),
    "Delete Basa": t("basa-delete", "Delete Basa"),
    "Delete Basque": t("basque-delete", "Delete Basque"),
    "Delete Belarusian": t("belarusian-delete", "Delete Belarusian"),
    "Delete Bemba": t("bemba-delete", "Delete Bemba"),
    "Delete Bena": t("bena-delete", "Delete Bena"),
    "Delete Bengali": t("bengali-delete", "Delete Bengali"),
    "Delete Bodo": t("bodo-delete", "Delete Bodo"),
    "Delete Bosnian": t("bosnian-delete", "Delete Bosnian"),
    "Delete Breton": t("breton-delete", "Delete Breton"),
    "Delete Bulgarian": t("bulgarian-delete", "Delete Bulgarian"),
    "Delete Burmese": t("burmese-delete", "Delete Burmese"),
    "Delete Cambodian": t("cambodian-delete", "Delete Cambodian"),
    "Delete Catalan": t("catalan-delete", "Delete Catalan"),
    "Delete Central Atlas Tamazight": t("central-atlas-tamazight-delete", "Delete Central Atlas Tamazight"),
    "Delete Cherokee": t("cherokee-delete", "Delete Cherokee"),
    "Delete Chiga": t("chiga-delete", "Delete Chiga"),
    "Delete Chinese": t("chinese-delete", "Delete Chinese"),
    "Delete Congo Swahili": t("congo-swahili-delete", "Delete Congo Swahili"),
    "Delete Cornish": t("cornish-delete", "Delete Cornish"),
    "Delete Criol": t("criol-delete", "Delete Criol"),
    "Delete Croatian": t("croatian-delete", "Delete Croatian"),
    "Delete Czech": t("czech-delete", "Delete Czech"),
    "Delete Danish": t("danish-delete", "Delete Danish"),
    "Delete Dawida": t("dawida-delete", "Delete Dawida"),
    "Delete Douala": t("douala-delete", "Delete Douala"),
    "Delete Dutch": t("dutch-delete", "Delete Dutch"),
    "Delete Dzongkha": t("dzongkha-delete", "Delete Dzongkha"),
    "Delete Ekugusii": t("ekugusii-delete", "Delete Ekugusii"),
    "Delete English": t("english-delete", "Delete English"),
    "Delete Esperanto": t("esperanto-delete", "Delete Esperanto"),
    "Delete Estonian": t("estonian-delete", "Delete Estonian"),
    "Delete Ewe": t("ewe-delete", "Delete Ewe"),
    "Delete Ewondo": t("ewondo-delete", "Delete Ewondo"),
    "Delete Faroese": t("faroese-delete", "Delete Faroese"),
    "Delete Farsi": t("farsi-delete", "Delete Farsi"),
    "Delete Filipino": t("filipino-delete", "Delete Filipino"),
    "Delete Finnish": t("finnish-delete", "Delete Finnish"),
    "Delete French": t("french-delete", "Delete French"),
    "Delete Galician": t("galician-delete", "Delete Galician"),
    "Delete Ganda": t("ganda-delete", "Delete Ganda"),
    "Delete Georgian": t("georgian-delete", "Delete Georgian"),
    "Delete German": t("german-delete", "Delete German"),
    "Delete German Swiss": t("german-swiss-delete", "Delete German Swiss"),
    "Delete Greek": t("greek-delete", "Delete Greek"),
    "Delete Greenlandic": t("greenlandic-delete", "Delete Greenlandic"),
    "Delete Guarani": t("guarani-delete", "Delete Guarani"),
    "Delete Gujarati": t("gujarati-delete", "Delete Gujarati"),
    "Delete Hausa": t("hausa-delete", "Delete Hausa"),
    "Delete Hawaiian": t("hawaiian-delete", "Delete Hawaiian"),
    "Delete Hebrew": t("hebrew-delete", "Delete Hebrew"),
    "Delete Hindi": t("hindi-delete", "Delete Hindi"),
    "Delete Hungarian": t("hungarian-delete", "Delete Hungarian"),
    "Delete Icelandic": t("icelandic-delete", "Delete Icelandic"),
    "Delete Igbo": t("igbo-delete", "Delete Igbo"),
    "Delete Indonesian": t("indonesian-delete", "Delete Indonesian"),
    "Delete Inuktitut": t("inuktitut-delete", "Delete Inuktitut"),
    "Delete Irish": t("irish-delete", "Delete Irish"),
    "Delete Italian": t("italian-delete", "Delete Italian"),
    "Delete Japanese": t("japanese-delete", "Delete Japanese"),
    "Delete Javanese": t("javanese-delete", "Delete Javanese"),
    "Delete Jola-Fonyi": t("jola-fonyi-delete", "Delete Jola-Fonyi"),
    "Delete Kabyle": t("kabyle-delete", "Delete Kabyle"),
    "Delete Kalenjin": t("kalenjin-delete", "Delete Kalenjin"),
    "Delete Kamba": t("kamba-delete", "Delete Kamba"),
    "Delete Kannada": t("kannada-delete", "Delete Kannada"),
    "Delete Kashmiri": t("kashmiri-delete", "Delete Kashmiri"),
    "Delete Kazakh": t("kazakh-delete", "Delete Kazakh"),
    "Delete Kiembu": t("kiembu-delete", "Delete Kiembu"),
    "Delete Kikuyu": t("kikuyu-delete", "Delete Kikuyu"),
    "Delete Kinyarwanda": t("kinyarwanda-delete", "Delete Kinyarwanda"),
    "Delete Kirghiz": t("kirghiz-delete", "Delete Kirghiz"),
    "Delete Konkani": t("konkani-delete", "Delete Konkani"),
    "Delete Korean": t("korean-delete", "Delete Korean"),
    "Delete Koyraboro Senni": t("koyraboro-senni-delete", "Delete Koyraboro Senni"),
    "Delete Kurdish": t("kurdish-delete", "Delete Kurdish"),
    "Delete Lao": t("lao-delete", "Delete Lao"),
    "Delete Latin": t("latin-delete", "Delete Latin"),
    "Delete Latvian": t("latvian-delete", "Delete Latvian"),
    "Delete Lingala": t("lingala-delete", "Delete Lingala"),
    "Delete Lithuanian": t("lithuanian-delete", "Delete Lithuanian"),
    "Delete Luba-Katanga": t("luba-katanga-delete", "Delete Luba-Katanga"),
    "Delete Luo": t("luo-delete", "Delete Luo"),
    "Delete Luxembourgish": t("luxembourgish-delete", "Delete Luxembourgish"),
    "Delete Luyia": t("luyia-delete", "Delete Luyia"),
    "Delete Macedonian": t("macedonian-delete", "Delete Macedonian"),
    "Delete Machame": t("machame-delete", "Delete Machame"),
    "Delete Makhuwa-Meetto": t("makhuwa-meetto-delete", "Delete Makhuwa-Meetto"),
    "Delete Makonde": t("makonde-delete", "Delete Makonde"),
    "Delete Malagasy": t("malagasy-delete", "Delete Malagasy"),
    "Delete Malay": t("malay-delete", "Delete Malay"),
    "Delete Malayalam": t("malayalam-delete", "Delete Malayalam"),
    "Delete Maltese": t("maltese-delete", "Delete Maltese"),
    "Delete Manx": t("manx-delete", "Delete Manx"),
    "Delete Marathi": t("marathi-delete", "Delete Marathi"),
    "Delete Masai": t("masai-delete", "Delete Masai"),
    "Delete Meru": t("meru-delete", "Delete Meru"),
    "Delete Moldavian": t("moldavian-delete", "Delete Moldavian"),
    "Delete Mongolian": t("mongolian-delete", "Delete Mongolian"),
    "Delete Morisyen": t("morisyen-delete", "Delete Morisyen"),
    "Delete Mundang": t("mundang-delete", "Delete Mundang"),
    "Delete Nama": t("nama-delete", "Delete Nama"),
    "Delete Nepali": t("nepali-delete", "Delete Nepali"),
    "Delete North Ndebele": t("north-ndebele-delete", "Delete North Ndebele"),
    "Delete Norwegian": t("norwegian-delete", "Delete Norwegian"),
    "Delete Nuer": t("nuer-delete", "Delete Nuer"),
    "Delete Nyankole": t("nyankole-delete", "Delete Nyankole"),
    "Delete Nynorsk": t("nynorsk-delete", "Delete Nynorsk"),
    "Delete Oriya": t("oriya-delete", "Delete Oriya"),
    "Delete Oromo": t("oromo-delete", "Delete Oromo"),
    "Delete Pashto": t("pashto-delete", "Delete Pashto"),
    "Delete Peul": t("peul-delete", "Delete Peul"),
    "Delete Polish": t("polish-delete", "Delete Polish"),
    "Delete Portuguese": t("portuguese-delete", "Delete Portuguese"),
    "Delete Punjabi": t("punjabi-delete", "Delete Punjabi"),
    "Delete Quechua": t("quechua-delete", "Delete Quechua"),
    "Delete Raeto Romance": t("raeto-romance-delete", "Delete Raeto Romance"),
    "Delete Romanian": t("romanian-delete", "Delete Romanian"),
    "Delete Rombo": t("rombo-delete", "Delete Rombo"),
    "Delete Rundi": t("rundi-delete", "Delete Rundi"),
    "Delete Russian": t("russian-delete", "Delete Russian"),
    "Delete Rwa": t("rwa-delete", "Delete Rwa"),
    "Delete Samburu": t("samburu-delete", "Delete Samburu"),
    "Delete Sami": t("sami-delete", "Delete Sami"),
    "Delete Sangu": t("sangu-delete", "Delete Sangu"),
    "Delete Sanskrit": t("sanskrit-delete", "Delete Sanskrit"),
    "Delete Scottish": t("scottish-delete", "Delete Scottish"),
    "Delete Sena": t("sena-delete", "Delete Sena"),
    "Delete Serbian": t("serbian-delete", "Delete Serbian"),
    "Delete Shambala": t("shambala-delete", "Delete Shambala"),
    "Delete Shona": t("shona-delete", "Delete Shona"),
    "Delete Sichuan Yi": t("sichuan-yi-delete", "Delete Sichuan Yi"),
    "Delete Sindhi": t("sindhi-delete", "Delete Sindhi"),
    "Delete Sinhalese": t("sinhalese-delete", "Delete Sinhalese"),
    "Delete Slovak": t("slovak-delete", "Delete Slovak"),
    "Delete Slovenian": t("slovenian-delete", "Delete Slovenian"),
    "Delete Soga": t("soga-delete", "Delete Soga"),
    "Delete Somali": t("somali-delete", "Delete Somali"),
    "Delete Songhay": t("songhay-delete", "Delete Songhay"),
    "Delete South Ndebele": t("south-ndebele-delete", "Delete South Ndebele"),
    "Delete Southern Sotho": t("southern-sotho-delete", "Delete Southern Sotho"),
    "Delete Spanish": t("spanish-delete", "Delete Spanish"),
    "Delete Sundanese": t("sundanese-delete", "Delete Sundanese"),
    "Delete Swahili": t("swahili-delete", "Delete Swahili"),
    "Delete Swati": t("swati-delete", "Delete Swati"),
    "Delete Swedish": t("swedish-delete", "Delete Swedish"),
    "Delete Tachelhit": t("tachelhit-delete", "Delete Tachelhit"),
    "Delete Tagalog": t("tagalog-delete", "Delete Tagalog"),
    "Delete Tajiki": t("tajiki-delete", "Delete Tajiki"),
    "Delete Tamil": t("tamil-delete", "Delete Tamil"),
    "Delete Tasawaq": t("tasawaq-delete", "Delete Tasawaq"),
    "Delete Tatar": t("tatar-delete", "Delete Tatar"),
    "Delete Telugu": t("telugu-delete", "Delete Telugu"),
    "Delete Teso": t("teso-delete", "Delete Teso"),
    "Delete Thai": t("thai-delete", "Delete Thai"),
    "Delete Tibetan": t("tibetan-delete", "Delete Tibetan"),
    "Delete Tigrinya": t("tigrinya-delete", "Delete Tigrinya"),
    "Delete Tongan": t("tongan-delete", "Delete Tongan"),
    "Delete Tsonga": t("tsonga-delete", "Delete Tsonga"),
    "Delete Tswana": t("tswana-delete", "Delete Tswana"),
    "Delete Turkish": t("turkish-delete", "Delete Turkish"),
    "Delete Turkmen": t("turkmen-delete", "Delete Turkmen"),
    "Delete Uighur": t("uighur-delete", "Delete Uighur"),
    "Delete Ukrainian": t("ukrainian-delete", "Delete Ukrainian"),
    "Delete Urdu": t("urdu-delete", "Delete Urdu"),
    "Delete Uzbek": t("uzbek-delete", "Delete Uzbek"),
    "Delete Vai": t("vai-delete", "Delete Vai"),
    "Delete Venda": t("venda-delete", "Delete Venda"),
    "Delete Vietnamese": t("vietnamese-delete", "Delete Vietnamese"),
    "Delete Vunjo": t("vunjo-delete", "Delete Vunjo"),
    "Delete Welsh": t("welsh-delete", "Delete Welsh"),
    "Delete Xhosa": t("xhosa-delete", "Delete Xhosa"),
    "Delete Yangben": t("yangben-delete", "Delete Yangben"),
    "Delete Yiddish": t("yiddish-delete", "Delete Yiddish"),
    "Delete Yoruba": t("yoruba-delete", "Delete Yoruba"),
    "Delete Zarma": t("zarma-delete", "Delete Zarma"),
    "Delete Zulu": t("zulu-delete", "Delete Zulu"),
    Afrikaans: t("afrikaans-language", "Afrikaans"),
    Aghem: t("aghem-language", "Aghem"),
    Akan: t("akan-language", "Akan"),
    Albanian: t("albanian-language", "Albanian"),
    Amharic: t("amharic-language", "Amharic"),
    Arabic: t("arabic-language", "Arabic"),
    Armenian: t("armenian-language", "Armenian"),
    Assamese: t("assamese-language", "Assamese"),
    Asu: t("asu-language", "Asu"),
    Aymara: t("aymara-language", "Aymara"),
    Azerbaijani: t("azerbaijani-language", "Azerbaijani"),
    Bafia: t("bafia-language", "Bafia"),
    Bambara: t("bambara-language", "Bambara"),
    Basa: t("basa-language", "Basa"),
    Basque: t("basque-language", "Basque"),
    Belarusian: t("belarusian-language", "Belarusian"),
    Bemba: t("bemba-language", "Bemba"),
    Bena: t("bena-language", "Bena"),
    Bengali: t("bengali-language", "Bengali"),
    Bodo: t("bodo-language", "Bodo"),
    Bosnian: t("bosnian-language", "Bosnian"),
    Breton: t("breton-language", "Breton"),
    Bulgarian: t("bulgarian-language", "Bulgarian"),
    Burmese: t("burmese-language", "Burmese"),
    Cambodian: t("cambodian-language", "Cambodian"),
    Catalan: t("catalan-language", "Catalan"),
    "Central Atlas Tamazight": t("central-atlas-tamazight-language", "Central Atlas Tamazight"),
    Cherokee: t("cherokee-language", "Cherokee"),
    Chiga: t("chiga-language", "Chiga"),
    Chinese: t("chinese-language", "Chinese"),
    "Congo Swahili": t("congo-swahili-language", "Congo Swahili"),
    Cornish: t("cornish-language", "Cornish"),
    Criol: t("criol-language", "Criol"),
    Croatian: t("croatian-language", "Croatian"),
    Czech: t("czech-language", "Czech"),
    Danish: t("danish-language", "Danish"),
    Dawida: t("dawida-language", "Dawida"),
    Douala: t("douala-language", "Douala"),
    Dutch: t("dutch-language", "Dutch"),
    Dzongkha: t("dzongkha-language", "Dzongkha"),
    Ekugusii: t("ekugusii-language", "Ekugusii"),
    English: t("english-language", "English"),
    Esperanto: t("esperanto-language", "Esperanto"),
    Estonian: t("estonian-language", "Estonian"),
    Ewe: t("ewe-language", "Ewe"),
    Ewondo: t("ewondo-language", "Ewondo"),
    Faroese: t("faroese-language", "Faroese"),
    Farsi: t("farsi-language", "Farsi"),
    Filipino: t("filipino-language", "Filipino"),
    Finnish: t("finnish-language", "Finnish"),
    French: t("french-language", "French"),
    Galician: t("galician-language", "Galician"),
    Ganda: t("ganda-language", "Ganda"),
    Georgian: t("georgian-language", "Georgian"),
    German: t("german-language", "German"),
    "German Swiss": t("german-swiss-language", "German Swiss"),
    Greek: t("greek-language", "Greek"),
    Greenlandic: t("greenlandic-language", "Greenlandic"),
    Guarani: t("guarani-language", "Guarani"),
    Gujarati: t("gujarati-language", "Gujarati"),
    Hausa: t("hausa-language", "Hausa"),
    Hawaiian: t("hawaiian-language", "Hawaiian"),
    Hebrew: t("hebrew-language", "Hebrew"),
    Hindi: t("hindi-language", "Hindi"),
    Hungarian: t("hungarian-language", "Hungarian"),
    Icelandic: t("icelandic-language", "Icelandic"),
    Igbo: t("igbo-language", "Igbo"),
    Indonesian: t("indonesian-language", "Indonesian"),
    Inuktitut: t("inuktitut-language", "Inuktitut"),
    Irish: t("irish-language", "Irish"),
    Italian: t("italian-language", "Italian"),
    Japanese: t("japanese-language", "Japanese"),
    Javanese: t("javanese-language", "Javanese"),
    "Jola-Fonyi": t("jola-fonyi-language", "Jola-Fonyi"),
    Kabyle: t("kabyle-language", "Kabyle"),
    Kalenjin: t("kalenjin-language", "Kalenjin"),
    Kamba: t("kamba-language", "Kamba"),
    Kannada: t("kannada-language", "Kannada"),
    Kashmiri: t("kashmiri-language", "Kashmiri"),
    Kazakh: t("kazakh-language", "Kazakh"),
    Kiembu: t("kiembu-language", "Kiembu"),
    Kikuyu: t("kikuyu-language", "Kikuyu"),
    Kinyarwanda: t("kinyarwanda-language", "Kinyarwanda"),
    Kirghiz: t("kirghiz-language", "Kirghiz"),
    Konkani: t("konkani-language", "Konkani"),
    Korean: t("korean-language", "Korean"),
    "Koyraboro Senni": t("koyraboro-senni-language", "Koyraboro Senni"),
    Kurdish: t("kurdish-language", "Kurdish"),
    Lao: t("lao-language", "Lao"),
    Latin: t("latin-language", "Latin"),
    Latvian: t("latvian-language", "Latvian"),
    Lingala: t("lingala-language", "Lingala"),
    Lithuanian: t("lithuanian-language", "Lithuanian"),
    "Luba-Katanga": t("luba-katanga-language", "Luba-Katanga"),
    Luo: t("luo-language", "Luo"),
    Luxembourgish: t("luxembourgish-language", "Luxembourgish"),
    Luyia: t("luyia-language", "Luyia"),
    Macedonian: t("macedonian-language", "Macedonian"),
    Machame: t("machame-language", "Machame"),
    "Makhuwa-Meetto": t("makhuwa-meetto-language", "Makhuwa-Meetto"),
    Makonde: t("makonde-language", "Makonde"),
    Malagasy: t("malagasy-language", "Malagasy"),
    Malay: t("malay-language", "Malay"),
    Malayalam: t("malayalam-language", "Malayalam"),
    Maltese: t("maltese-language", "Maltese"),
    Manx: t("manx-language", "Manx"),
    Marathi: t("marathi-language", "Marathi"),
    Masai: t("masai-language", "Masai"),
    Meru: t("meru-language", "Meru"),
    Moldavian: t("moldavian-language", "Moldavian"),
    Mongolian: t("mongolian-language", "Mongolian"),
    Morisyen: t("morisyen-language", "Morisyen"),
    Mundang: t("mundang-language", "Mundang"),
    Nama: t("nama-language", "Nama"),
    Nepali: t("nepali-language", "Nepali"),
    "North Ndebele": t("north-ndebele-language", "North Ndebele"),
    Norwegian: t("norwegian-language", "Norwegian"),
    Nuer: t("nuer-language", "Nuer"),
    Nyankole: t("nyankole-language", "Nyankole"),
    Nynorsk: t("nynorsk-language", "Nynorsk"),
    Oriya: t("oriya-language", "Oriya"),
    Oromo: t("oromo-language", "Oromo"),
    Pashto: t("pashto-language", "Pashto"),
    Peul: t("peul-language", "Peul"),
    Polish: t("polish-language", "Polish"),
    Portuguese: t("portuguese-language", "Portuguese"),
    Punjabi: t("punjabi-language", "Punjabi"),
    Quechua: t("quechua-language", "Quechua"),
    "Raeto Romance": t("raeto-romance-language", "Raeto Romance"),
    Romanian: t("romanian-language", "Romanian"),
    Rombo: t("rombo-language", "Rombo"),
    Rundi: t("rundi-language", "Rundi"),
    Russian: t("russian-language", "Russian"),
    Rwa: t("rwa-language", "Rwa"),
    Samburu: t("samburu-language", "Samburu"),
    Sami: t("sami-language", "Sami"),
    Sangu: t("sangu-language", "Sangu"),
    Sanskrit: t("sanskrit-language", "Sanskrit"),
    Scottish: t("scottish-language", "Scottish"),
    Sena: t("sena-language", "Sena"),
    Serbian: t("serbian-language", "Serbian"),
    Shambala: t("shambala-language", "Shambala"),
    Shona: t("shona-language", "Shona"),
    "Sichuan Yi": t("sichuan-yi-language", "Sichuan Yi"),
    Sindhi: t("sindhi-language", "Sindhi"),
    Sinhalese: t("sinhalese-language", "Sinhalese"),
    Slovak: t("slovak-language", "Slovak"),
    Slovenian: t("slovenian-language", "Slovenian"),
    Soga: t("soga-language", "Soga"),
    Somali: t("somali-language", "Somali"),
    Songhay: t("songhay-language", "Songhay"),
    "South Ndebele": t("south-ndebele-language", "South Ndebele"),
    "Southern Sotho": t("southern-sotho-language", "Southern Sotho"),
    Spanish: t("spanish-language", "Spanish"),
    Sundanese: t("sundanese-language", "Sundanese"),
    Swahili: t("swahili-language", "Swahili"),
    Swati: t("swati-language", "Swati"),
    Swedish: t("swedish-language", "Swedish"),
    Tachelhit: t("tachelhit-language", "Tachelhit"),
    Tagalog: t("tagalog-language", "Tagalog"),
    Tajiki: t("tajiki-language", "Tajiki"),
    Tamil: t("tamil-language", "Tamil"),
    Tasawaq: t("tasawaq-language", "Tasawaq"),
    Tatar: t("tatar-language", "Tatar"),
    Telugu: t("telugu-language", "Telugu"),
    Teso: t("teso-language", "Teso"),
    Thai: t("thai-language", "Thai"),
    Tibetan: t("tibetan-language", "Tibetan"),
    Tigrinya: t("tigrinya-language", "Tigrinya"),
    Tongan: t("tongan-language", "Tongan"),
    Tsonga: t("tsonga-language", "Tsonga"),
    Tswana: t("tswana-language", "Tswana"),
    Turkish: t("turkish-language", "Turkish"),
    Turkmen: t("turkmen-language", "Turkmen"),
    Uighur: t("uighur-language", "Uighur"),
    Ukrainian: t("ukrainian-language", "Ukrainian"),
    Urdu: t("urdu-language", "Urdu"),
    Uzbek: t("uzbek-language", "Uzbek"),
    Vai: t("vai-language", "Vai"),
    Venda: t("venda-language", "Venda"),
    Vietnamese: t("vietnamese-language", "Vietnamese"),
    Vunjo: t("vunjo-language", "Vunjo"),
    Welsh: t("welsh-language", "Welsh"),
    Xhosa: t("xhosa-language", "Xhosa"),
    Yangben: t("yangben-language", "Yangben"),
    Yiddish: t("yiddish-language", "Yiddish"),
    Yoruba: t("yoruba-language", "Yoruba"),
    Zarma: t("zarma-language", "Zarma"),
    Zulu: t("zulu-language", "Zulu"),
    Other: t("other-language", "Other"),
    'Language "Afrikaans" has been deleted': t(
      "afrikaans-deleted-language",
      'Language "Afrikaans" has been deleted'
    ),
    'Language "Aghem" has been deleted': t("aghem-deleted-language", 'Language "Aghem" has been deleted'),
    'Language "Akan" has been deleted': t("akan-deleted-language", 'Language "Akan" has been deleted'),
    'Language "Albanian" has been deleted': t("albanian-deleted-language", 'Language "Albanian" has been deleted'),
    'Language "Amharic" has been deleted': t("amharic-deleted-language", 'Language "Amharic" has been deleted'),
    'Language "Arabic" has been deleted': t("arabic-deleted-language", 'Language "Arabic" has been deleted'),
    'Language "Armenian" has been deleted': t("armenian-deleted-language", 'Language "Armenian" has been deleted'),
    'Language "Assamese" has been deleted': t("assamese-deleted-language", 'Language "Assamese" has been deleted'),
    'Language "Asu" has been deleted': t("asu-deleted-language", 'Language "Asu" has been deleted'),
    'Language "Aymara" has been deleted': t("aymara-deleted-language", 'Language "Aymara" has been deleted'),
    'Language "Azerbaijani" has been deleted': t(
      "azerbaijani-deleted-language",
      'Language "Azerbaijani" has been deleted'
    ),
    'Language "Bafia" has been deleted': t("bafia-deleted-language", 'Language "Bafia" has been deleted'),
    'Language "Bambara" has been deleted': t("bambara-deleted-language", 'Language "Bambara" has been deleted'),
    'Language "Basa" has been deleted': t("basa-deleted-language", 'Language "Basa" has been deleted'),
    'Language "Basque" has been deleted': t("basque-deleted-language", 'Language "Basque" has been deleted'),
    'Language "Belarusian" has been deleted': t(
      "belarusian-deleted-language",
      'Language "Belarusian" has been deleted'
    ),
    'Language "Bemba" has been deleted': t("bemba-deleted-language", 'Language "Bemba" has been deleted'),
    'Language "Bena" has been deleted': t("bena-deleted-language", 'Language "Bena" has been deleted'),
    'Language "Bengali" has been deleted': t("bengali-deleted-language", 'Language "Bengali" has been deleted'),
    'Language "Bodo" has been deleted': t("bodo-deleted-language", 'Language "Bodo" has been deleted'),
    'Language "Bosnian" has been deleted': t("bosnian-deleted-language", 'Language "Bosnian" has been deleted'),
    'Language "Breton" has been deleted': t("breton-deleted-language", 'Language "Breton" has been deleted'),
    'Language "Bulgarian" has been deleted': t(
      "bulgarian-deleted-language",
      'Language "Bulgarian" has been deleted'
    ),
    'Language "Burmese" has been deleted': t("burmese-deleted-language", 'Language "Burmese" has been deleted'),
    'Language "Cambodian" has been deleted': t(
      "cambodian-deleted-language",
      'Language "Cambodian" has been deleted'
    ),
    'Language "Catalan" has been deleted': t("catalan-deleted-language", 'Language "Catalan" has been deleted'),
    'Language "Central Atlas Tamazight" has been deleted': t(
      "central-atlas-tamazight-deleted-language",
      'Language "Central Atlas Tamazight" has been deleted'
    ),
    'Language "Cherokee" has been deleted': t("cherokee-deleted-language", 'Language "Cherokee" has been deleted'),
    'Language "Chiga" has been deleted': t("chiga-deleted-language", 'Language "Chiga" has been deleted'),
    'Language "Chinese" has been deleted': t("chinese-deleted-language", 'Language "Chinese" has been deleted'),
    'Language "Congo Swahili" has been deleted': t(
      "congo-swahili-deleted-language",
      'Language "Congo Swahili" has been deleted'
    ),
    'Language "Cornish" has been deleted': t("cornish-deleted-language", 'Language "Cornish" has been deleted'),
    'Language "Criol" has been deleted': t("criol-deleted-language", 'Language "Criol" has been deleted'),
    'Language "Croatian" has been deleted': t("croatian-deleted-language", 'Language "Croatian" has been deleted'),
    'Language "Czech" has been deleted': t("czech-deleted-language", 'Language "Czech" has been deleted'),
    'Language "Danish" has been deleted': t("danish-deleted-language", 'Language "Danish" has been deleted'),
    'Language "Dawida" has been deleted': t("dawida-deleted-language", 'Language "Dawida" has been deleted'),
    'Language "Douala" has been deleted': t("douala-deleted-language", 'Language "Douala" has been deleted'),
    'Language "Dutch" has been deleted': t("dutch-deleted-language", 'Language "Dutch" has been deleted'),
    'Language "Dzongkha" has been deleted': t("dzongkha-deleted-language", 'Language "Dzongkha" has been deleted'),
    'Language "Ekugusii" has been deleted': t("ekugusii-deleted-language", 'Language "Ekugusii" has been deleted'),
    'Language "English" has been deleted': t("english-deleted-language", 'Language "English" has been deleted'),
    'Language "Esperanto" has been deleted': t(
      "esperanto-deleted-language",
      'Language "Esperanto" has been deleted'
    ),
    'Language "Estonian" has been deleted': t("estonian-deleted-language", 'Language "Estonian" has been deleted'),
    'Language "Ewe" has been deleted': t("ewe-deleted-language", 'Language "Ewe" has been deleted'),
    'Language "Ewondo" has been deleted': t("ewondo-deleted-language", 'Language "Ewondo" has been deleted'),
    'Language "Faroese" has been deleted': t("faroese-deleted-language", 'Language "Faroese" has been deleted'),
    'Language "Farsi" has been deleted': t("farsi-deleted-language", 'Language "Farsi" has been deleted'),
    'Language "Filipino" has been deleted': t("filipino-deleted-language", 'Language "Filipino" has been deleted'),
    'Language "Finnish" has been deleted': t("finnish-deleted-language", 'Language "Finnish" has been deleted'),
    'Language "French" has been deleted': t("french-deleted-language", 'Language "French" has been deleted'),
    'Language "Galician" has been deleted': t("galician-deleted-language", 'Language "Galician" has been deleted'),
    'Language "Ganda" has been deleted': t("ganda-deleted-language", 'Language "Ganda" has been deleted'),
    'Language "Georgian" has been deleted': t("georgian-deleted-language", 'Language "Georgian" has been deleted'),
    'Language "German" has been deleted': t("german-deleted-language", 'Language "German" has been deleted'),
    'Language "German Swiss" has been deleted': t(
      "german-swiss-deleted-language",
      'Language "German Swiss" has been deleted'
    ),
    'Language "Greek" has been deleted': t("greek-deleted-language", 'Language "Greek" has been deleted'),
    'Language "Greenlandic" has been deleted': t(
      "greenlandic-deleted-language",
      'Language "Greenlandic" has been deleted'
    ),
    'Language "Guarani" has been deleted': t("guarani-deleted-language", 'Language "Guarani" has been deleted'),
    'Language "Gujarati" has been deleted': t("gujarati-deleted-language", 'Language "Gujarati" has been deleted'),
    'Language "Hausa" has been deleted': t("hausa-deleted-language", 'Language "Hausa" has been deleted'),
    'Language "Hawaiian" has been deleted': t("hawaiian-deleted-language", 'Language "Hawaiian" has been deleted'),
    'Language "Hebrew" has been deleted': t("hebrew-deleted-language", 'Language "Hebrew" has been deleted'),
    'Language "Hindi" has been deleted': t("hindi-deleted-language", 'Language "Hindi" has been deleted'),
    'Language "Hungarian" has been deleted': t(
      "hungarian-deleted-language",
      'Language "Hungarian" has been deleted'
    ),
    'Language "Icelandic" has been deleted': t(
      "icelandic-deleted-language",
      'Language "Icelandic" has been deleted'
    ),
    'Language "Igbo" has been deleted': t("igbo-deleted-language", 'Language "Igbo" has been deleted'),
    'Language "Indonesian" has been deleted': t(
      "indonesian-deleted-language",
      'Language "Indonesian" has been deleted'
    ),
    'Language "Inuktitut" has been deleted': t(
      "inuktitut-deleted-language",
      'Language "Inuktitut" has been deleted'
    ),
    'Language "Irish" has been deleted': t("irish-deleted-language", 'Language "Irish" has been deleted'),
    'Language "Italian" has been deleted': t("italian-deleted-language", 'Language "Italian" has been deleted'),
    'Language "Japanese" has been deleted': t("japanese-deleted-language", 'Language "Japanese" has been deleted'),
    'Language "Javanese" has been deleted': t("javanese-deleted-language", 'Language "Javanese" has been deleted'),
    'Language "Jola-Fonyi" has been deleted': t(
      "jola-fonyi-deleted-language",
      'Language "Jola-Fonyi" has been deleted'
    ),
    'Language "Kabyle" has been deleted': t("kabyle-deleted-language", 'Language "Kabyle" has been deleted'),
    'Language "Kalenjin" has been deleted': t("kalenjin-deleted-language", 'Language "Kalenjin" has been deleted'),
    'Language "Kamba" has been deleted': t("kamba-deleted-language", 'Language "Kamba" has been deleted'),
    'Language "Kannada" has been deleted': t("kannada-deleted-language", 'Language "Kannada" has been deleted'),
    'Language "Kashmiri" has been deleted': t("kashmiri-deleted-language", 'Language "Kashmiri" has been deleted'),
    'Language "Kazakh" has been deleted': t("kazakh-deleted-language", 'Language "Kazakh" has been deleted'),
    'Language "Kiembu" has been deleted': t("kiembu-deleted-language", 'Language "Kiembu" has been deleted'),
    'Language "Kikuyu" has been deleted': t("kikuyu-deleted-language", 'Language "Kikuyu" has been deleted'),
    'Language "Kinyarwanda" has been deleted': t(
      "kinyarwanda-deleted-language",
      'Language "Kinyarwanda" has been deleted'
    ),
    'Language "Kirghiz" has been deleted': t("kirghiz-deleted-language", 'Language "Kirghiz" has been deleted'),
    'Language "Konkani" has been deleted': t("konkani-deleted-language", 'Language "Konkani" has been deleted'),
    'Language "Korean" has been deleted': t("korean-deleted-language", 'Language "Korean" has been deleted'),
    'Language "Koyraboro Senni" has been deleted': t(
      "koyraboro-senni-deleted-language",
      'Language "Koyraboro Senni" has been deleted'
    ),
    'Language "Kurdish" has been deleted': t("kurdish-deleted-language", 'Language "Kurdish" has been deleted'),
    'Language "Lao" has been deleted': t("lao-deleted-language", 'Language "Lao" has been deleted'),
    'Language "Latin" has been deleted': t("latin-deleted-language", 'Language "Latin" has been deleted'),
    'Language "Latvian" has been deleted': t("latvian-deleted-language", 'Language "Latvian" has been deleted'),
    'Language "Lingala" has been deleted': t("lingala-deleted-language", 'Language "Lingala" has been deleted'),
    'Language "Lithuanian" has been deleted': t(
      "lithuanian-deleted-language",
      'Language "Lithuanian" has been deleted'
    ),
    'Language "Luba-Katanga" has been deleted': t(
      "luba-katanga-deleted-language",
      'Language "Luba-Katanga" has been deleted'
    ),
    'Language "Luo" has been deleted': t("luo-deleted-language", 'Language "Luo" has been deleted'),
    'Language "Luxembourgish" has been deleted': t(
      "luxembourgish-deleted-language",
      'Language "Luxembourgish" has been deleted'
    ),
    'Language "Luyia" has been deleted': t("luyia-deleted-language", 'Language "Luyia" has been deleted'),
    'Language "Macedonian" has been deleted': t(
      "macedonian-deleted-language",
      'Language "Macedonian" has been deleted'
    ),
    'Language "Machame" has been deleted': t("machame-deleted-language", 'Language "Machame" has been deleted'),
    'Language "Makhuwa-Meetto" has been deleted': t(
      "makhuwa-meetto-deleted-language",
      'Language "Makhuwa-Meetto" has been deleted'
    ),
    'Language "Makonde" has been deleted': t("makonde-deleted-language", 'Language "Makonde" has been deleted'),
    'Language "Malagasy" has been deleted': t("malagasy-deleted-language", 'Language "Malagasy" has been deleted'),
    'Language "Malay" has been deleted': t("malay-deleted-language", 'Language "Malay" has been deleted'),
    'Language "Malayalam" has been deleted': t(
      "malayalam-deleted-language",
      'Language "Malayalam" has been deleted'
    ),
    'Language "Maltese" has been deleted': t("maltese-deleted-language", 'Language "Maltese" has been deleted'),
    'Language "Manx" has been deleted': t("manx-deleted-language", 'Language "Manx" has been deleted'),
    'Language "Marathi" has been deleted': t("marathi-deleted-language", 'Language "Marathi" has been deleted'),
    'Language "Masai" has been deleted': t("masai-deleted-language", 'Language "Masai" has been deleted'),
    'Language "Meru" has been deleted': t("meru-deleted-language", 'Language "Meru" has been deleted'),
    'Language "Moldavian" has been deleted': t(
      "moldavian-deleted-language",
      'Language "Moldavian" has been deleted'
    ),
    'Language "Mongolian" has been deleted': t(
      "mongolian-deleted-language",
      'Language "Mongolian" has been deleted'
    ),
    'Language "Morisyen" has been deleted': t("morisyen-deleted-language", 'Language "Morisyen" has been deleted'),
    'Language "Mundang" has been deleted': t("mundang-deleted-language", 'Language "Mundang" has been deleted'),
    'Language "Nama" has been deleted': t("nama-deleted-language", 'Language "Nama" has been deleted'),
    'Language "Nepali" has been deleted': t("nepali-deleted-language", 'Language "Nepali" has been deleted'),
    'Language "North Ndebele" has been deleted': t(
      "north-ndebele-deleted-language",
      'Language "North Ndebele" has been deleted'
    ),
    'Language "Norwegian" has been deleted': t(
      "norwegian-deleted-language",
      'Language "Norwegian" has been deleted'
    ),
    'Language "Nuer" has been deleted': t("nuer-deleted-language", 'Language "Nuer" has been deleted'),
    'Language "Nyankole" has been deleted': t("nyankole-deleted-language", 'Language "Nyankole" has been deleted'),
    'Language "Nynorsk" has been deleted': t("nynorsk-deleted-language", 'Language "Nynorsk" has been deleted'),
    'Language "Oriya" has been deleted': t("oriya-deleted-language", 'Language "Oriya" has been deleted'),
    'Language "Oromo" has been deleted': t("oromo-deleted-language", 'Language "Oromo" has been deleted'),
    'Language "Pashto" has been deleted': t("pashto-deleted-language", 'Language "Pashto" has been deleted'),
    'Language "Peul" has been deleted': t("peul-deleted-language", 'Language "Peul" has been deleted'),
    'Language "Polish" has been deleted': t("polish-deleted-language", 'Language "Polish" has been deleted'),
    'Language "Portuguese" has been deleted': t(
      "portuguese-deleted-language",
      'Language "Portuguese" has been deleted'
    ),
    'Language "Punjabi" has been deleted': t("punjabi-deleted-language", 'Language "Punjabi" has been deleted'),
    'Language "Quechua" has been deleted': t("quechua-deleted-language", 'Language "Quechua" has been deleted'),
    'Language "Raeto Romance" has been deleted': t(
      "raeto-romance-deleted-language",
      'Language "Raeto Romance" has been deleted'
    ),
    'Language "Romanian" has been deleted': t("romanian-deleted-language", 'Language "Romanian" has been deleted'),
    'Language "Rombo" has been deleted': t("rombo-deleted-language", 'Language "Rombo" has been deleted'),
    'Language "Rundi" has been deleted': t("rundi-deleted-language", 'Language "Rundi" has been deleted'),
    'Language "Russian" has been deleted': t("russian-deleted-language", 'Language "Russian" has been deleted'),
    'Language "Rwa" has been deleted': t("rwa-deleted-language", 'Language "Rwa" has been deleted'),
    'Language "Samburu" has been deleted': t("samburu-deleted-language", 'Language "Samburu" has been deleted'),
    'Language "Sami" has been deleted': t("sami-deleted-language", 'Language "Sami" has been deleted'),
    'Language "Sangu" has been deleted': t("sangu-deleted-language", 'Language "Sangu" has been deleted'),
    'Language "Sanskrit" has been deleted': t("sanskrit-deleted-language", 'Language "Sanskrit" has been deleted'),
    'Language "Scottish" has been deleted': t("scottish-deleted-language", 'Language "Scottish" has been deleted'),
    'Language "Sena" has been deleted': t("sena-deleted-language", 'Language "Sena" has been deleted'),
    'Language "Serbian" has been deleted': t("serbian-deleted-language", 'Language "Serbian" has been deleted'),
    'Language "Shambala" has been deleted': t("shambala-deleted-language", 'Language "Shambala" has been deleted'),
    'Language "Shona" has been deleted': t("shona-deleted-language", 'Language "Shona" has been deleted'),
    'Language "Sichuan Yi" has been deleted': t(
      "sichuan-yi-deleted-language",
      'Language "Sichuan Yi" has been deleted'
    ),
    'Language "Sindhi" has been deleted': t("sindhi-deleted-language", 'Language "Sindhi" has been deleted'),
    'Language "Sinhalese" has been deleted': t(
      "sinhalese-deleted-language",
      'Language "Sinhalese" has been deleted'
    ),
    'Language "Slovak" has been deleted': t("slovak-deleted-language", 'Language "Slovak" has been deleted'),
    'Language "Slovenian" has been deleted': t(
      "slovenian-deleted-language",
      'Language "Slovenian" has been deleted'
    ),
    'Language "Soga" has been deleted': t("soga-deleted-language", 'Language "Soga" has been deleted'),
    'Language "Somali" has been deleted': t("somali-deleted-language", 'Language "Somali" has been deleted'),
    'Language "Songhay" has been deleted': t("songhay-deleted-language", 'Language "Songhay" has been deleted'),
    'Language "South Ndebele" has been deleted': t(
      "south-ndebele-deleted-language",
      'Language "South Ndebele" has been deleted'
    ),
    'Language "Southern Sotho" has been deleted': t(
      "southern-sotho-deleted-language",
      'Language "Southern Sotho" has been deleted'
    ),
    'Language "Spanish" has been deleted': t("spanish-deleted-language", 'Language "Spanish" has been deleted'),
    'Language "Sundanese" has been deleted': t(
      "sundanese-deleted-language",
      'Language "Sundanese" has been deleted'
    ),
    'Language "Swahili" has been deleted': t("swahili-deleted-language", 'Language "Swahili" has been deleted'),
    'Language "Swati" has been deleted': t("swati-deleted-language", 'Language "Swati" has been deleted'),
    'Language "Swedish" has been deleted': t("swedish-deleted-language", 'Language "Swedish" has been deleted'),
    'Language "Tachelhit" has been deleted': t(
      "tachelhit-deleted-language",
      'Language "Tachelhit" has been deleted'
    ),
    'Language "Tagalog" has been deleted': t("tagalog-deleted-language", 'Language "Tagalog" has been deleted'),
    'Language "Tajiki" has been deleted': t("tajiki-deleted-language", 'Language "Tajiki" has been deleted'),
    'Language "Tamil" has been deleted': t("tamil-deleted-language", 'Language "Tamil" has been deleted'),
    'Language "Tasawaq" has been deleted': t("tasawaq-deleted-language", 'Language "Tasawaq" has been deleted'),
    'Language "Tatar" has been deleted': t("tatar-deleted-language", 'Language "Tatar" has been deleted'),
    'Language "Telugu" has been deleted': t("telugu-deleted-language", 'Language "Telugu" has been deleted'),
    'Language "Teso" has been deleted': t("teso-deleted-language", 'Language "Teso" has been deleted'),
    'Language "Thai" has been deleted': t("thai-deleted-language", 'Language "Thai" has been deleted'),
    'Language "Tibetan" has been deleted': t("tibetan-deleted-language", 'Language "Tibetan" has been deleted'),
    'Language "Tigrinya" has been deleted': t("tigrinya-deleted-language", 'Language "Tigrinya" has been deleted'),
    'Language "Tongan" has been deleted': t("tongan-deleted-language", 'Language "Tongan" has been deleted'),
    'Language "Tsonga" has been deleted': t("tsonga-deleted-language", 'Language "Tsonga" has been deleted'),
    'Language "Tswana" has been deleted': t("tswana-deleted-language", 'Language "Tswana" has been deleted'),
    'Language "Turkish" has been deleted': t("turkish-deleted-language", 'Language "Turkish" has been deleted'),
    'Language "Turkmen" has been deleted': t("turkmen-deleted-language", 'Language "Turkmen" has been deleted'),
    'Language "Uighur" has been deleted': t("uighur-deleted-language", 'Language "Uighur" has been deleted'),
    'Language "Ukrainian" has been deleted': t(
      "ukrainian-deleted-language",
      'Language "Ukrainian" has been deleted'
    ),
    'Language "Urdu" has been deleted': t("urdu-deleted-language", 'Language "Urdu" has been deleted'),
    'Language "Uzbek" has been deleted': t("uzbek-deleted-language", 'Language "Uzbek" has been deleted'),
    'Language "Vai" has been deleted': t("vai-deleted-language", 'Language "Vai" has been deleted'),
    'Language "Venda" has been deleted': t("venda-deleted-language", 'Language "Venda" has been deleted'),
    'Language "Vietnamese" has been deleted': t(
      "vietnamese-deleted-language",
      'Language "Vietnamese" has been deleted'
    ),
    'Language "Vunjo" has been deleted': t("vunjo-deleted-language", 'Language "Vunjo" has been deleted'),
    'Language "Welsh" has been deleted': t("welsh-deleted-language", 'Language "Welsh" has been deleted'),
    'Language "Xhosa" has been deleted': t("xhosa-deleted-language", 'Language "Xhosa" has been deleted'),
    'Language "Yangben" has been deleted': t("yangben-deleted-language", 'Language "Yangben" has been deleted'),
    'Language "Yiddish" has been deleted': t("yiddish-deleted-language", 'Language "Yiddish" has been deleted'),
    'Language "Yoruba" has been deleted': t("yoruba-deleted-language", 'Language "Yoruba" has been deleted'),
    'Language "Zarma" has been deleted': t("zarma-deleted-language", 'Language "Zarma" has been deleted'),
    'Language "Zulu" has been deleted': t("zulu-deleted-language", 'Language "Zulu" has been deleted'),
  };
};

export type LanguageLabelsOptions = keyof ReturnType<typeof languageLabelsGetter>;
export type LanguageLabels = ReturnType<typeof languageLabelsGetter>;
export function isLanguageLabel(label: any, labels: LanguageLabels): label is LanguageLabelsOptions {
  return !!labels[label as LanguageLabelsOptions];
}

export default languageLabelsGetter;
