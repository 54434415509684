/**
 * @owner @Appboy/reporting
 */

import type { I18nT } from "@lib/dashboardI18n";

export type ConversionLabelsTrans = I18nT<"conversion-labels">;

const conversionLabelsGetter = (t: I18nT<"conversion-labels">) => ({
  None: t("default-conversion-label", "None"),
  "Primary Conversion Event - A": t("primary-conversion-event-a", "Primary Conversion Event - A"),
  "Conversion Event - B": t("conversion-event-b", "Conversion Event - B"),
  "Conversion Event - C": t("conversion-event-c", "Conversion Event - C"),
  "Conversion Event - D": t("conversion-event-d", "Conversion Event - D"),
  "Event A (primary)": t("conversion-event-a-brief", "Event A (primary)"),
  "Event B": t("conversion-event-b-brief", "Event B"),
  "Event C": t("conversion-event-c-brief", "Event C"),
  "Event D": t("conversion-event-d-brief", "Event D"),
  "Conversions - A": t("conversions-stat-names-a", "Conversions - A"),
  "Conversions - B": t("conversions-stat-names-b", "Conversions - B"),
  "Conversions - C": t("conversions-stat-names-c", "Conversions - C"),
  "Conversions - D": t("conversions-stat-names-d", "Conversions - D"),
  Conversions: t("conversions", "Conversions"),
});

export type ConversionLabelsOptions = keyof ReturnType<typeof conversionLabelsGetter>;

export default conversionLabelsGetter;
