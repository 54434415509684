/**
 * @owner @Appboy/email @Appboy/sms
 */

import type { I18nT } from "@lib/dashboardI18n";

export type ErrorLabelsTrans = I18nT<"error-labels">;

const errorLabelsGetter = (t: I18nT<"error-labels">) => {
  return {
    "Keywords cannot contain spaces": t("keywords-spaces-error", "Keywords cannot contain spaces"),
    "Keyword cannot be empty": t("keywords-empty-error", "Keyword cannot be empty"),
    "Unsuccessful Save": t("unsuccessful-save", "Unsuccessful Save"),
    "Instructions for General Errors": t(
      "general-error-body",
      "Please try refreshing the page and trying your operation again."
    ),
    "Header for General Errors": t("general-error-header", "Oops! We encountered and logged an error."),
    "Info on Maximum Custom Keyword Categories": t(
      "max-custom-keyword-categories-error-body",
      "Subscription Groups are limited to 10 custom keywords. To create a new custom keyword, please delete an existing one."
    ),
    "Custom Keyword limit exceeded": t("custom-keyword-limit-exceeded", "Custom Keyword limit exceeded"),
    "Spaces are not allowed": t("spaces-not-allowed-in-keyword", "Spaces are not allowed"),
    "Keyword Category can only include Alphanumeric Characters": t(
      "keyword-category-name-only-alphanumeric",
      "Keyword Category Name can only include alphanumeric characters"
    ),
    "Something went wrong.": t("something-went-wrong", "Something went wrong."),
    "Deleting in use Keyword Category": t(
      "deleting-in-use-keyword-category",
      "Keyword category cannot be deleted because it's already in use"
    ),
    "Archiving in use Subscription Group": t(
      "archiving-in-use-subscription-group",
      "Subscription group cannot be archived because it's already in use"
    ),
    "Archiving Subscription Group": t("archiving-subscription-group", "Subscription group cannot be archived"),
    "Unarchiving Subscription Group": t(
      "unarchiving-subscription-group",
      "Subscription group cannot be unarchived"
    ),
    "Choose at least one option from the list": t(
      "choose-at-least-one-option",
      "Choose at least one option from the list"
    ),
  };
};

export type ErrorLabelsOptions = keyof ReturnType<typeof errorLabelsGetter>;

export default errorLabelsGetter;
