import type { I18nT } from "@lib/dashboardI18n";

export type NotificationLabelsTrans = I18nT<"notification-constants">;

const notificationLabelsGetter = (t: I18nT<"notification-constants">) => {
  return {
    "Technical Error": t("type-technical-error", "Technical Error"),
    Notification: t("type-notification", "Notification"),
    "Payment and Billing": t("payment-and-billing-name", "Payment and Billing"),
    "AWS Credential Errors": t("aws-credentials-name", "AWS Credential Errors"),
    "Scheduled Campaign Sent/Not Sent": t("marketing-campaign-delivery-name", "Scheduled Campaign Sent/Not Sent"),
    "Scheduled Campaign Limit Met": t("campaign-limit-met-name", "Scheduled Campaign Limit Met"),
    "Scheduled Campaign Finished Sending": t(
      "scheduled-campaign-finished-sending-name",
      "Scheduled Campaign Finished Sending"
    ),
    "Push Credential Errors": t("push-credentials-name", "Push Errors"),
    "Newsfeed Card Published/Live": t("news-feed-card-live-name", "Newsfeed Card Published/Live"),
    "Weekly Analytics Report": t("weekly-analytics-report-name", "Weekly Analytics Report"),
    "Campaign/Canvas Updated": t("marketing-object-updated-name", "Campaign/Canvas Updated"),
    "Invalid Azure Credentials": t("azure-credentials-name", "Invalid Azure Credentials"),
    "Campaign Interaction Expiration": t("campaign-interaction-expiration-name", "Campaign Interaction Expiration"),
    "Webhooks are not available for this notification": t(
      "name-no-webhooks-available",
      "Webhooks are not available for this notification"
    ),
    "Webhook Errors": t("webhook-errors", "Webhook Errors"),
    "Connected Content Errors": t("connected-content-errors", "Connected Content Errors"),
  };
};

export type NotificationLabelsOptions = keyof ReturnType<typeof notificationLabelsGetter>;

export default notificationLabelsGetter;
