/**
 * @owner @Appboy/core-objects
 */

import type { I18nT } from "@lib/dashboardI18n";

export type NestedSchemaTypeTrans = I18nT<"nested-schema-types">;

const nestedSchemaTypeLabelsGetter = (t: I18nT<"nested-schema-types">) => {
  return {
    boolean: t("boolean", "Boolean"),
    "boolean array": t("boolean-array", "Boolean Array"),
    object: t("object", "Object"),
    "object array": t("object-array", "Object Array"),
    number: t("number", "Number"),
    "number array": t("number-array", "Number Array"),
    string: t("string", "String"),
    "string array": t("string-array", "String Array"),
    time: t("time", "Time"),
    "time array": t("time-array", "Time Array"),
    "empty array": t("empty-array", "Empty Array"),
  };
};

export type NestedSchemaTypeLabelsOptions = keyof ReturnType<typeof nestedSchemaTypeLabelsGetter>;

export default nestedSchemaTypeLabelsGetter;
