/**
 * @owner @Appboy/messaging-and-automation
 */

import type { I18nT } from "@lib/dashboardI18n";

const entryScheduleLabelsGetter = (t: I18nT<"entry-schedule-labels">) => ({
  "entry-schedule": t("entry-schedule-filter-label", "Entry schedule"),
  "one-time": t("entry-schedule-filter-one-time-label", "One Time"),
  recurring: t("entry-schedule-filter-recurring-label", "Recurring"),
  "action-based": t("entry-schedule-filter-action-based-label", "Action-Based"),
  "api-triggered": t("entry-schedule-filter-api-triggered-label", "API-Triggered"),
});

export default entryScheduleLabelsGetter;
